import store from '../store'
import communicator from './communicator'
export default {
  getNotifications()
  {     
    communicator.get("notifications/")
      .then(function(data)
      {
        var notifications = [];

        for(var i = 0; i < data.length; i++)
        {
          data[i].level = JSON.parse(data[i].level);
          notifications.push(data[i]);
        }

        store.commit('setEntity', { entityName: 'notifications', entity: notifications })            
      })
      .fail(function(error) {
      
      });        
  }
  
}