<script>
import Form from '@/components/Form/Form.vue'
import MultiSelect from '@/components/Form/MultiSelect.vue'
import InvitationDocuments from '@/components/InvitationDocuments.vue'
import UploadDocuments from '@/components/UploadDocuments.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'

export default {
    components:{ Form, MultiSelect, UploadDocuments, PopUp, UserInstruction, InvitationDocuments },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        if(this.$route.params.id != undefined)
        {
            this.getInvitation(this.$route.params.id);
        } else
        {
            this.invitation = { 
                id: 0, 
                title: '', 
                description: '', 
                user_ids: [], 
                recipient_group_id: null,
                work_group_ids: [], 
                start_date: null, 
                end_date: null, 
                enable_answers: false, 
                show_answers: false,
                files: []
            }; 
        }    

        this.$cache.createCache('boardMembers', 'board-members/');
        this.$cache.createCache('workGroups', 'work-groups/');

    },
    data() {
        return {            
            invitation: null, 
            isEditing: true,
            isSaving: false,
            editRecipients: true,
            tab: 1,
            tabsComplete: 0,
            invitationSent: false,
            uploadDocuments: false,
            contentForm: [                 
                    { sections: [{ sectionName: 'Innehåll', classes: "g-100", inputs: [ 
                             { type: 'text', prop: 'title', title: 'Titel', classes: "g-100", required: true },
                             { type: 'textEditor', prop: 'description', title: 'Meddelande', classes: "g-100", required: true },
                            ]
                        }, { sectionName: 'Datum och tid', classes: "g-100", inputs: [
                                { type: 'date', prop: 'date', title: 'Datum', startWithDateNow: true, classes: "g-50", required: true },                                
                                { type: 'select', prop: 'start_time', title: 'Tid', classes: "g-50", required: true , selectItems: 'quarters'},
                            ]
                        },
                        { sectionName: 'Svar från deltagare', classes: "g-100", inputs: [
                                { type: 'switch', prop: 'enable_answers', title: 'Ge deltagaren möjlighet att tacka ja/nej', classes: "g-50" },                                
                                { type: 'switch', prop: 'show_answers', title: 'Gör svaren synliga för alla deltagare', classes: "g-50" },
                            ]
                        }],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                    },                                    
                ]           
        }
    },   
    methods:
    {
        getInvitation(id)
        {
            var self = this;
            this.$communicator.get('admin-invitations/' + id)
                .then(function(data)
                {
                   self.invitation = data;
                   self.isEditing = false;
                   self.tabsComplete = 4;
                   self.uploadDocuments = false;
                   self.editRecipients = false;
                })
                .fail(function(error) {
                    
                });
        },
        changeTab(tabNo)
        {
            if(this.tabsComplete+1 >= tabNo)
            {
                this.tab = tabNo; 
            }        
        },
        selectRecipientGroupId(id)
        {          
            if(id != 3) {
                this.invitation.work_group_ids = [];
            }
            else if(id != 4)
            {
                this.invitation.user_ids = [];
            }

            this.invitation.recipient_group_id = id; 
        },
        updateInvitation(invitation)
        {
            if(invitation != undefined)
            {
                this.tabsComplete = this.tabsComplete == 0 ? this.tabsComplete = 2 : this.tabsComplete;
                this.invitation = invitation;
            }
            else if(this.invitation.id != 0)
            {
                this.getInvitation(this.invitation.id);
            }
            else
            {
                this.$router.push({ name: 'adminInvitations' }); 
            }
        },
        sendInvitation()
        {
            this.isSaving = true;
            var self = this;
            this.$communicator.post('admin-send-invitation/', this.invitation)
                .then(function()
                {
                    self.isSaving = false;
                    self.invitationSent = true;
                })
                .fail(function(error) {
                    
                });
        },
        saveRecipients()
        {
            var self = this;
            this.isSaving = true;
            this.$communicator.post('admin-invitations/', this.invitation)
                .then(function(data)
                {
                   self.invitation = data;
                   self.isEditing = false;
                   self.isSaving = false;
                   self.tabsComplete = 3;
                   self.editRecipients = false;
                   message.setSuccessMessage('Härligt! Dina mottagare är sparade och du kan nu ta dig till \'4. Sammanfattning\' för att granska samt skicka ut din inbjudan');
                })
                .fail(function(error) {
                    
                });
        },
        backToInvitations()
        {
            this.$router.push({ name: 'adminInvitations' }); 
        },
        goToRecipients()
        {
            this.tabsComplete = 2;
            this.tab = 3;
        },
        deleteDocument(id)
        {
            var self = this;
            this.$communicator.post('invitation/'+this.invitation.id+'/documents/'+id+'/delete')
                .then(function(data)
                {                 
                    self.getInvitation(self.invitation.id);
                })
                .fail(function(error) {                  
                })  
        },   
    },
    computed:
    {
        user() {return this.$store.state.user; },   
        users() { return this.$store.state.users != null ? this.$store.state.users : null },   
        recipientGroups() { return this.$store.state.recipientGroups != null ? this.$store.state.recipientGroups: [] },
        boardMembers() { return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : [] },
        workGroups() { return this.$store.state.workGroups != null ? this.$store.state.workGroups : null }  
    }
}
</script>
<template>
    <div v-fade-in v-if="users != null && workGroups != null && invitation != null">
        <main class="opacity-admin-bg overflow-auto position-relative" v-if="users != null" style="margin-bottom:100px">          
            <div class="float-right">              
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminInvitations' }">Inbjudan</router-link>
                &nbsp; >>  &nbsp;
                <b v-if="invitation.id == 0">Lägg till ny inbjudan</b>
                <b v-else>{{invitation.title}}</b>
            </div>
            <ul>
                <li @click="changeTab(1)" :class="[tab == 1 ? 'wow-color' : '']"><h2>1. Innehåll & tidpunkt</h2></li>
                <li @click="changeTab(2)" :class="[tab == 2 ? 'wow-color' : '']"><h2 :style="{'opacity' : tabsComplete < 1 ? '0.3' : '1.0'}">2. Bifoga filer</h2></li>
                <li @click="changeTab(3)" :class="[tab == 3 ? 'wow-color' : '']"><h2 :style="{'opacity' : tabsComplete < 2 ? '0.3' : '1.0'}">3. Mottagare</h2></li>
                <li @click="changeTab(4)" :class="[tab == 4 ? 'wow-color' : '']"><h2 :style="{'opacity' : tabsComplete < 3 ? '0.3' : '1.0'}">4. Sammanfattning</h2></li>
            </ul> 
            
            <div v-show="tab == 1">
                <Form 
                    :form="contentForm" 
                    :item="invitation" 
                    :url="'admin-invitations/'" 
                    :getItemFunction="updateInvitation" 
                    :inEditMode="isEditing" 
                    :successMessage="invitation.id == 0 ? 'Din inbjudan är på god väg, klicka på \'2. Bifoga filer\'  för att bifoga filer eller klicka på \'3. Mottagare\' för att lägga till.' : 'Dina ändringar är sparade.'">
                </Form> 
                <button class="btn float-right" v-show="invitation.id != 0 && !isEditing && tabsComplete == 0" @click="changeTab(2)">Vidare till bifoga filer</button>
            </div>
            <div v-show="tab == 2">  
                <div v-if="invitation.files.length > 0">
                    <InvitationDocuments :invitationId="invitation.id" :documents="invitation.files" :documentTitle="'Bifogade filer'" :deleteFunction="deleteDocument"></InvitationDocuments>
                    <button class="btn float-right" @click="uploadDocuments = true"> Bifoga fler dokument</button>
                </div>
                <div v-else style="text-align: center;">
                    <button class="btn" @click="uploadDocuments = true" style="width:50%;margin:80px 0px;">Klicka här om du vill bifoga filer till din inbjudan</button>
                </div>
                <div v-if="uploadDocuments">
                    <PopUp>            
                        <UploadDocuments :type="'invitation'" :id="invitation.id"></UploadDocuments> 
                        <button class="btn float-right" @click="getInvitation(invitation.id)">Klar</button>
                    </PopUp>
                </div>
            </div>
            <div v-show="tab == 3">
                <div v-if="editRecipients">
                    Vilka ska få inbjudan?<br>
                    <div class="overflow-auto">
                        <button class="btn AdminInvitationsAdd-btn" @click="selectRecipientGroupId(group.id)" v-for="group in recipientGroups" :class="[group.id != invitation.recipient_group_id ? 'btn-gray': '']">{{ group.name }}</button>                   
                    </div>
                    <div v-if="invitation.recipient_group_id == 1">
                        <h4>Samtliga medlemmar</h4>
                    <div v-for="user in users" class="AdminInvitationsAdd-users-list"> 
                            {{ user.full_name }}
                        </div>
                    </div>
                    <div v-if="invitation.recipient_group_id == 2">
                        <h4>Styrelsemedlemmar</h4>
                    <div v-for="boardMember in boardMembers" class="AdminInvitationsAdd-users-list"> 
                            {{ boardMember.user.full_name }} ({{  boardMember.board_member_type }})
                        </div>
                    </div>
                    <div v-if="invitation.recipient_group_id == 3">
                        <br>
                        Välj arbetsgrupp/arbetsgrupper från listan
                        <MultiSelect v-model:selectedItemSysIDs="invitation.work_group_ids" :selectItems="'workGroups'"></MultiSelect>                
                    </div>
                    <div v-if="invitation.recipient_group_id == 4">
                        <br>
                        Välj medlemmar från listan
                        <MultiSelect v-model:selectedItemSysIDs="invitation.user_ids" :selectItems="'users'"></MultiSelect>                   
                    </div>
                    <button class="btn float-right" v-show="invitation.recipient_group_id != null" @click="saveRecipients" :disabled="isSaving">Spara</button>
                </div>
                <div v-else>
                    Dessa medlemmar får denna inbjudan:
                    <div v-if="invitation.recipient_group_id == 1">
                        <h4>Samtliga medlemmar</h4>
                    <div v-for="user in users" class="AdminInvitationsAdd-users-list"> 
                            {{ user.full_name }}
                        </div>
                    </div>
                    <div v-if="invitation.recipient_group_id == 2">
                        <h4>Styrelsemedlemmar</h4>
                    <div v-for="boardMember in boardMembers" class="AdminInvitationsAdd-users-list"> 
                            {{ boardMember.user.full_name }} ({{  boardMember.board_member_type }})
                        </div>
                    </div>
                    <div v-if="invitation.recipient_group_id == 3">
                        <div v-for="recipient in invitation.recipients" class="AdminInvitationsAdd-users-list">
                            {{ recipient.user.full_name }} ({{ recipient.work_group != null ? recipient.work_group.name : '' }})<br>
                        </div>                 
                    </div>
                    <div v-if="invitation.recipient_group_id == 4">
                        <br>
                        <div v-for="user in invitation.users" class="AdminInvitationsAdd-users-list">
                            <b>{{ user }}</b><br>
                        </div>    
                    </div>  
                    <button class="btn float-right" @click="editRecipients = true">Ändra mottagare</button>
                </div>
            </div>
            <div v-show="tab == 4">
                Följande inbjudan kommer skickas:
                <div style="background-color: white; padding: 20px; width: 80%; margin: auto; border-radius: 5px; border: 1px solid #ccc; margin-top: 20px; margin-bottom:20px;">                
                   
                    <h3 class="text-center">{{ invitation.title }} </h3>
                    <b>Datum: </b>{{invitation.formatted_date }}<br>
                    <b>Tid: </b>{{invitation.start_time }} <br><br>
                    <div v-html="invitation.description"></div>
                </div>

                Dessa medlemmar får denna inbjudan:
                <div v-if="invitation.recipient_group_id == 1">
                    <h4>Samtliga medlemmar</h4>
                   <div v-for="user in users" class="AdminInvitationsAdd-users-list"> 
                        {{ user.full_name }}
                    </div>
                </div>
                <div v-if="invitation.recipient_group_id == 2">
                    <h4>Styrelsemedlemmar</h4>
                   <div v-for="boardMember in boardMembers" class="AdminInvitationsAdd-users-list"> 
                        {{ boardMember.user.full_name }} ({{  boardMember.board_member_type }})
                    </div>
                </div>
                <div v-if="invitation.recipient_group_id == 3">
                    <div v-for="recipient in invitation.recipients" class="AdminInvitationsAdd-users-list">
                        {{ recipient.user.full_name }} ({{ recipient.work_group != null ? recipient.work_group.name : '' }})<br>
                    </div>                 
                </div>
                <div v-if="invitation.recipient_group_id == 4">
                    <br>
                    <div v-for="user in invitation.users" class="AdminInvitationsAdd-users-list">
                        <b>{{ user }}</b><br>
                    </div>    
                </div>  
                <div class="float-right">
                    <button class="btn" @click="sendInvitation" :disabled="isSaving">Skicka inbjudan</button>         
                </div>
            </div>             
        </main>
    </div>
    <div v-if="invitationSent">
    <PopUp :width="'500px'">
      <div class="AdminInvitationsAdd-popup">
        <i class="fa-regular fa-paper-plane" style="margin: 30px 0px; display: block;"></i>
        Din inbjudan har skickats iväg på mail till berörda mottagare. <br>   
        <button class="btn margin-top-15" @click="backToInvitations">Tillbaka till inbjudningar</button>
      </div>
    </PopUp>
  </div>
</template>
<style scoped>

    .AdminInvitationsAdd-bottom-btn
    {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

   ul{
    display: block;
    list-style: none;
    overflow: auto;
   }

   li{
    padding: 20px 0px;
    width: 25%;
    float:left;
    text-align: center;   
    cursor: pointer; 
   }

   li:hover{
    background-color: rgb(252, 239, 239);
   }

   .AdminInvitationsAdd-btn
   {
    padding:10px 20px;
    margin: 10px 5px;
   }

   .AdminInvitationsAdd-users-list
   {
    line-height: 25px;
   }

   .AdminInvitationsAdd-popup
    {
    line-height: 30px;
    padding-bottom:30px;
    }

    .fa-paper-plane
    {
        font-size: 40pt;
    }
   
</style>