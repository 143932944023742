<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import UploadExcel from '@/components/Excel/UploadExcel.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction, UploadExcel },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        
        this.$cache.createCache('statutes', 'statutes/');
    },
    data() {
        return {  
            uploadType: { 
                tableHeaders: ['Stadga'], 
                isStatutesUpload: true, 
                importButtonText: 'Importera Stadgar',
                successMessage: 'Härligt, dina stagar är nu tillagda i jamii.', 
                url: 'upload-statutes'
            },
            columns: [                             
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'statute', displayName: 'Kategori', width: 300, hideFilter: true, isTextEditorFormat: true},
                { name: 'created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'statute', displayName: 'Stadga' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            form: [                   
                    { sections: [
                        { sectionName: 'Lägg till en stadga', classes: "g-100", inputs: [
                            { type: 'textEditor', prop: 'statute', title: 'Stadga', classes: "g-100", required: true }, 
                         
                            ]
                        }]
                    }],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, statute: ''};
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('statutes/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getStatutes();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getStatutes()
        {
            this.editItem = null;
            this.$cache.updateCache('statutes', 'statutes/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        statutes() {  
            return this.$store.state.statutes != undefined ? this.$store.state.statutes.map(x => (
                {
                    id: x.id, 
                    created_date: this.$formatters.date(x.created_date, 'date'), 
                    statute: x.statute,
                    created_by: x.created_by_user.full_name
                })) : []
            }        
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en stadga</button>
                <UploadExcel :type="uploadType"></UploadExcel>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Stadgar'"></ToAdmin>
            <h2>Stadgar</h2>
           
                <div v-if="editItem != null">
                    <PopUp>
                        <Form :form="form" :item="editItem" :url="'statutes/'" :getItemFunction="getStatutes" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List :items="statutes" :columns="columns" :noOfFrozenColumns="4" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'stadgar'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
            <div v-if="statutes.length == 0">
            Inga stadgar har lagts till.</div>
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>