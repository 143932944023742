<script>
import BoardMembers from '@/components/BoardMembers.vue'
import Members from '@/components/Members.vue'
import WorkGroups from '@/components/WorkGroups.vue'
import Statutes from '@/components/Statutes.vue'
import Documents from '@/components/Documents.vue'
import RulesOfProcedure from '@/components/RulesOfProcedure.vue'
import Tabs from '../components/Tabs.vue'
export default {
    components: { BoardMembers, Members, WorkGroups, Tabs, Statutes, RulesOfProcedure, Documents },
    data() {
        return {
            selectedTab: 'Styrelsen'
        };
    },
    created() {
        this.$cache.createCache('workGroups', 'work-groups/') 
        this.$cache.createCache('statutes', 'statutes/') 
        this.$cache.createCache('rulesOfProcedure', 'rules-of-procedure/') 
    },
    computed:
    {
        workGroups() {  return this.$store.state.workGroups },
        statutes() {  return this.$store.state.statutes },
        rulesOfProcedure() {  return this.$store.state.rulesOfProcedure },
        tabs() {
            var tabs = ['Styrelsen', 'Medlemmar'];
            if(this.workGroups != null && this.workGroups.length > 0)
            {
                tabs.push('Arbetsgrupper');
            }

            tabs.push('Dokument');
            
            if(this.statutes != null && this.statutes.length > 0)
            {
                tabs.push('Stadgar');
            }
            
            if(this.rulesOfProcedure != null && this.rulesOfProcedure.length > 0)
            {
                tabs.push('Ordningsregler');
            }  
            return tabs
        }
    }
};
</script>
<template>
    <div v-fade-in v-if="workGroups != null">
        <main class="opacity-white-bg">
            <h2>Föreningen</h2>
            <Tabs :tabs="tabs" v-model:selectedTab="selectedTab">               
            </Tabs>
           
            <div v-show="selectedTab == 'Styrelsen'">
                <BoardMembers></BoardMembers>
            </div>
            <div v-show="selectedTab == 'Medlemmar'">
                <Members></Members>
            </div>
            <div v-show="selectedTab == 'Arbetsgrupper'">
                <WorkGroups></WorkGroups>
            </div>
            <div v-show="selectedTab == 'Stadgar'">
                <Statutes></Statutes>
            </div>
            <div v-show="selectedTab == 'Ordningsregler'">
                <RulesOfProcedure></RulesOfProcedure>
            </div>
            <div v-show="selectedTab == 'Dokument'">
                <Documents></Documents>
            </div>
        </main>
    </div>

</template>
<style scoped>
    .Community-tabs-wrapper
    {
        width:100%;
        border-bottom: 1px solid black;
        margin-bottom: 15px;

    }

    .Community-tabs-wrapper h2 {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        margin: 0px;
    }
</style>
