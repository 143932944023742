<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import UploadDocuments from '@/components/UploadDocuments.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, UploadDocuments, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        
        var self = this;
        this.$cache.createCache('documents', 'document-categories/');
    },
    data() {
        return {   
            columns: [                                
            { name: 'edit', displayName: 'L', icon: 'fa-solid fa-download', align:'center', function: this.download, width: 30, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'T', isDelete: true, align:'center', width: 30, hideFilter: true, hideIfEdit: true},
                { name: 'name', displayName: 'Meddelanden', width: 500, hideFilter: true},
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'formatted_created_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null
        }
    },   
    methods:
    {
        download(item)
        {
            window.open(this.url + 'uploads/'+this.customer.id+'/documents/'+ item.name);
        },
        add()
        {
            this.editItem = { id: 0, message: ''};
        },
        deleteDocument(id)
        {
            var self = this;
            this.$communicator.post('documents/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getDocuments();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },     
        getDocuments()
        {
            this.editItem = null;
            this.$cache.updateCache('documents', 'document-categories/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        customer() {return this.$store.state.customer; },
        url() { return config.urlToServer },
        documentCategory() { return this.$store.state.documents == null ? null : this.$store.state.documents.find(x => x.id == this.$route.params.id) },
        documents() { return this.documentCategory.documents },
    }
}
</script>
<template>
    <div v-if="documentCategory" v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till ett eller flera dokument</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminDocumentCategories' }">Dokument</router-link>
                &nbsp; >>  &nbsp;
                <b>{{ documentCategory.name }}</b>
            </div>
            <h2>Dokument: {{ documentCategory.name }}</h2>          
                <div v-if="editItem != null">
                    <PopUp>
                        <UploadDocuments :type="'documents'" :categoryId="documentCategory.id"></UploadDocuments>                     
                        <button class="btn float-right" @click="getDocuments">Klar</button>
                    </PopUp>
                </div>
            <List :items="documents" :columns="columns" :noOfFrozenColumns="4" :deleteFunction="deleteDocument" :isEditing="editItem != null" :nameOfItems="'meddelanden'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>  
            <div v-if="documents.length == 0">Det finns inga dokument uppladdade</div>  
        </main>
    </div>
</template>
<style scoped>
   
</style>