import store from '../store'
import communicator from './communicator'
export default {
  getLookups()
  {     
    communicator.get("lookups/")
        .then(function(data)
        {                 
            store.commit('setEntity', { entityName: 'users', entity: data.users })
            store.commit('setEntity', { entityName: 'categories', entity: data.categories })
            store.commit('setEntity', { entityName: 'recipientGroups', entity: data.recipient_groups })
        })
        .fail(function(error) {
        
        });         
  }
  
}