<script>
import config from "@/helpers/config";
export default {
    props: ['documents', 'documentTitle', 'invitationId','deleteFunction'],
    data() {
        return {
        };
    },
    computed: {
    
        url() { return config.urlToServer },
        customer() {return this.$store.state.customer; },
    }
};
</script>
<template>          
    <div v-if="documents.length > 0">
                {{ documentTitle }}: <br>
                <div v-for="document in documents" class="InvitationDocuments-doc" v-hover :key="document.id">
                    <a :href="url + 'uploads/'+customer.id+'/invitations/'+invitationId+'/' + document.name" target="_blank">
                        <span v-if="document.file_type == 'pdf'">
                            <i class="fa-solid fa-file-pdf"></i>
                        </span>
                        <div v-else-if="document.file_type == 'docx' || document.file_type == 'doc'">
                            <i class="fa-solid fa-file-word"></i>
                        </div>
                        <div v-else-if="document.file_type == 'xlsx' || document.file_type == 'xls'">
                            <i class="fa-solid fa-file-excel"></i>
                        </div>
                        <span v-else-if="document.file_type == 'txt'">
                            <i class="fa-solid fa-file-lines"></i>
                        </span>
                        {{ document.name }}
                    </a>
                    &nbsp;<i v-if="deleteFunction != undefined" class="fa-solid fa-times" @click="deleteFunction(document.id)" style="cursor: pointer;" v-tooltip="'Ta bort dokumentet'"></i>
                </div>
            </div>
  </template>

<style scoped>
    .InvitationDocuments-doc{
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 10px 20px;
        margin: 10px 10px 10px 0px;
        background-color: rgb(243, 239, 239);
    }
</style>
