<script>
import Logo from '@/components/Logo.vue'
export default {
  components:{ Logo },
  data() {
    return {  
       
    }  
  },
  methods:
  {
    logOut() {
      this.$store.commit('setEntity', { entityName: 'user', entity: null });
      this.$cache.clearCache();
      localStorage.removeItem("samfallifyToken");      
    }
  },
  created(){
    this.logOut();
  }
}
</script>
<template>
  <section>
    <div class="logout-content">
      <Logo :width="150" :color="'rgb(225 182 117)'" :marginAuto="true"></Logo>      
      <p>Vi syns snart igen! :)</p>
      <router-link to="/login"><button class="btn"> Logga in igen</button></router-link>
    </div>
  </section>
</template>
<style scoped>
section{
  text-align: center;
  width:100%;
  height: 100vh;
  display:flex;
}
.logout-content
{
  width:300px;
  margin:auto;
  background-color: rgba(255,255,255,0.9);
  padding:60px 20px;
  text-align: center;
  border-radius: 4px;
}
i{
  display: block;
  font-size:60px;
  margin-top:15px;
}

.btn
{
  margin-top: 15px;
}

@media only screen and (max-width: 1000px) {

}
</style>
