<script>
import menuLinks from "@/helpers/menuLinks"
import Notification from '@/components/Notification.vue'
export default {
    components: {Notification },
    props: ['positionLeft'],
    data(){
        return {         
            selectedModule: 'Aktuellt',
            links: menuLinks.getLinks(),
            hideMenuPages: menuLinks.hideMenuPages
        }
    },
    created()
    {     
        this.$cache.createCache('boardMembers', 'board-members/')                
        $(".menu-item").hover(function(){
            $(this).css("background-color", "yellow");
            }, function(){
            $(this).css("background-color", "pink");
        });
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        },
        isAdmin()
        {
            return this.user.user_level == 'SamfallifyCrew' || this.user.user_level == 'SuperAdmin'
        },
        showMenu() {
            return !this.hideMenuPages.some(p => p == this.$route.name);
        },
        userWorkGroups() {  
            return this.$store.state.userWorkGroups != null ? this.$store.state.userWorkGroups : []; 
        },
        notifications() {        
            return this.$store.state.notifications != null ? this.$store.state.notifications : []; 
        },
        invitations() {  
            return this.$store.state.invitations;
         },
        isPartOfBoard()
        {
            return this.$store.state.boardMembers != null ? this.$store.state.boardMembers.some(x => x.user_id == this.user.id) : false;
        },
        workGroupId()
        {
            return this.$route.params.id; 
        }
    }
}
</script>
<template>
    <section id="menu-section" style="{left: positionLeft }"  v-show="showMenu" v-fade-in>
        <div style="padding-bottom: 40px">
            <div v-for="(link,index) in links" :key="index" class="position-relative">
                <Notification :level="'menu'" :levelName="link.text" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                <router-link :to="link.url" class="menu-item">
                        <i :class="[link.icon, link.name.some(p => p == this.$route.name) ? 'font-color' : '']"></i>
                        <span v-html="link.text"></span>
                        <hr class="border-color" v-if="link.name.some(p => p == this.$route.name)" v-fade-in />
                </router-link>          
            </div>  
            <div v-if="isPartOfBoard" class="position-relative">
                <Notification :level="'menu'" :levelName="'Styrelsen'" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                <router-link :to="'/canvas-board'" class="menu-item">
                        <i class='fa-solid fa-people-line'></i>
                        <span v-html="'Styrelsen'"></span>
                        <hr class="border-color" v-if="this.$route.name == 'canvasBoard'" v-fade-in />
                </router-link>  
            </div>  
            <div v-for="group in userWorkGroups" class="position-relative">
                <Notification :level="'menu'" :levelName="group.name" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                <router-link :to="'/canvas/' + group.id">
                        <i class="fa-solid fa-person-digging"></i>
                        <span v-html="group.name"></span>
                        <hr class="border-color" v-if="this.$route.name == 'canvasWorkGroup' && workGroupId == group.id" v-fade-in />
                </router-link>  
            </div>               
        </div>
    </section>   
</template>
<style scoped>
    section{     
       float:left;      
        width:130px;
        opacity: 1;
        padding-left:10px;
        background: linear-gradient(90deg, rgba(88,68,38,1) 0%, rgba(119,95,58,1) 100%);
        height: 100vh;
        position: fixed;
        z-index: 999;
        overflow-y: auto;
    }

    .menu-visible {
        transition-timing-function: ease-in;
        transition: 0.7s;
        opacity:1;
        margin-top: 70px;
    }

    hr
    {
        border-width: 5px;
        border-top: 0px;
        border-radius: 5px;
        border-left: 0px;
        position: absolute; 
        width: 100%;
    }

    a{
        width: 100px;
        padding-top: 18px;
        height: 60px;
        display: inline-block;
        cursor: pointer;
        margin-top: 5px;
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 12px;
        position: relative;
            
    }

    i {
        font-size: 24px;
        display: block;
        color: wheat;
    }
    span{
        color: white;
        font-weight: bold;
        display: block;
        margin-top: 7px;
    }
    a:hover {
        text-decoration: none;
    }


    /* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: red;  */
  background: rgb(109, 87, 53);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(97, 77, 48);
}
</style>