<script>
    export default {
        props: ['value','hasValidationErrors','prop'],
        emits: ['update:value'],
        data() {
            return {
               pw: null,
               visiblePw: false
            }
        },
        methods:
        {
          toggleVisibility()
            {
              var x = document.getElementById("Password-" + this.prop);
              if (x.type === "password") {
                x.type = "text";
                this.visiblePw = true;
              } else {
                x.type = "password";
                this.visiblePw = false;
              }  
            }
        },
        watch:
        {
          pw(newvalue)
          {
              this.$emit('update:value', newvalue); 
          }
        }   
    }
</script>

<template>
  <div class="position-relative">
    <input :id="'Password-' + prop" type="password" v-model="pw" :class="[hasValidationErrors ? 'validation-error-border':'']">
    <i v-show="!visiblePw" class="fa-solid fa-eye" @click="toggleVisibility"></i>
    <i v-show="visiblePw" class="fa-solid fa-eye-slash" @click="toggleVisibility"></i>
  </div>
</template>
<style scoped>
 input 
    {       
        width: calc(100% - 29px);
        margin:2px 0px 10px 0px;
    }

  .fa-solid
  {
    position:absolute;
    right: 16px;
    top: 11px;
  }

</style>