<script>
import  { Sketch }  from '@ckpack/vue-color';
export default {
    components:{ Sketch },
    props: ['color'],
    //emits: ['update:color'],
    data(){
        return {
           colors: this.color,
           showPicker: false
        }
    },
    methods:
    {
        changeTab(tab)
        {
           
        }
    },
    watch:
    {
        colors(newvalue){
            if(typeof newvalue === 'object')
            this.$emit('update:color',  newvalue.hex);
        }
    }
}
</script>
<template>
    <div class="ColorPickerPreview" :style="{ 'background-color': color }" @click="showPicker = true">

    </div>
    <div v-if="showPicker" class="ColorPicker-picker">
        <Sketch v-model="colors"></Sketch>
        <button class="btn float-right" @click="showPicker = false">Klar</button>
    </div>
</template>

<style scoped>
  .ColorPickerPreview
  {
    width: 28px;
    height: 28px;
    border: 1px solid black;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
  }

  .ColorPicker-picker
  {
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    width: 220px;
    padding: 5px;
    overflow: auto;
    position: absolute;
    z-index: 200;
  }
  .vc-sketch
  {
    border-radius: 0px !important;
    box-shadow: none !important;
  }
</style>