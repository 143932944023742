<script>
import AdminLink from '../components/AdminLink.vue'
import List from '../components/List/List.vue'
export default {
    components:{ AdminLink, List },
    created() {     
        this.$cache.createCache('members', 'members/') 
    },
    data() {
        return { 
            customer: this.$store.state.customer,                 
            sorting: [
                { name: 'last_name', displayName: 'Efternamn' },
                { name: 'first_name', displayName: 'Förnamn' }
            ],
        }
    },
    computed: {
        members() {  return this.$store.state.members == null ? [] : this.$store.state.members },
        user() {return this.$store.state.user; },
        columns() {
            var columns = [             
                { name: 'first_name', displayName: 'Förnamn', width: 200, hideFilter: true},
                { name: 'last_name', displayName: 'Efternamn', width: 200, hideFilter: true},
                { name: 'property_designation', displayName: 'Fastighetsbetäckning', width: 300, hideFilter: true},
                { name: 'is_active', displayName: 'Är aktiv i jamii', width: 150, hideFilter: true, isBoolean:true}
            ]

            if(!this.customer.disable_user_messages)
            {
                columns.unshift({name: 'send', displayName: 'M', isSendUserMessage: true, align: 'center', width: 30, hideFilter: true, hideIfEdit: true});
            }

            return columns;
        }
    }
}
</script>
<template>
    <AdminLink :buttonText="'Lägg till/ändra medlemmar'" :sectionToEdit="'adminMembers'"></AdminLink>
    <List :items="members" :columns="columns" :nameOfItems="'medlemmar'" :noOfFrozenColumns="this.$isMobile ? 1 : 2" :sorting="sorting"></List>           
</template>