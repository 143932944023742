<script>
    export default {
        props: ['model', 'prop'],
        data() {
            return {
             
            }
        },
        methods:
        {
          setHasChanges()
            {
              this.model.hasChanges = true;
            }
        }     
    }
</script>

<template>
     <label class="switch">
        <input class="Switch-slider" type="checkbox" v-model="model[prop]" @click="setHasChanges">
        <span class="slider round"></span>
    </label>
</template>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #a3bda4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #a3bda4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>