<script>
import Form from '@/components/Form/Form.vue'
import PopUp from '../components/PopUp.vue'
export default {
    components:{ Form, PopUp },  
    props: ['userMessage', 'closeUserMessageSender', 'isReply'],
    data() {
        return {  
            showPopUp: false,         
            form: []
        }
    },   
    methods:
    {
       
    },
    created()
    {
        this.form = [                   
            { sections: [
                { sectionName: 'Skriv meddelande till ' + this.userMessage.toUserName, classes: "g-100", inputs: [
                    { type: 'text', prop: 'subject', title: 'Ämne', classes: "g-100", required: true }, 
                    { type: 'textEditor', prop: 'message', title: 'Meddelande', classes: "g-100", required: true },                     
                    ]
                }]
            }];
    }
}
</script>
<template>
    <PopUp>
        <Form :form="form" v-if="!isReply" :item="userMessage" :saveText="'Skicka meddelandet'" :successMessage="'Ditt meddelande är iväg!'" :url="'send-user-message/'" :getItemFunction="closeUserMessageSender" :inEditMode="true"></Form>               
        <Form :form="form" v-if="isReply" :item="userMessage" :saveText="'Skicka meddelandet'" :successMessage="'Ditt meddelande är iväg!'" :url="'send-reply-user-message/'" :getItemFunction="closeUserMessageSender" :inEditMode="true"></Form>               
    </PopUp>
</template>
<style scoped>
</style>