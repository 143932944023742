<script>
import config from "@/helpers/config";
import PopUp from '@/components/PopUp.vue'
import UploadDocuments from '@/components/UploadDocuments.vue'
import message from '@/helpers/message'
export default {
    components: {PopUp, UploadDocuments },
    props: ['workGroupId', 'updateChat'],
    data() {
        return {
            addDocument: false,
            files: [],
            isBoard: this.workGroupId == undefined
        };
    },
    created() {
        this.getFiles();
    },
    methods:
    {
        getFiles(uploadedFile) {
            var self = this;

            var url = this.isBoard ? 'board-documents/' : 'work-group-documents/' + this.workGroupId;
            this.$communicator.get(url) 
                .then(function(data)
                {
                    self.files = data;
                    self.addDocument = false;
                    if(uploadedFile){
                        self.updateChat.timeToUpdate = true;
                    }
                })
                .fail(function(error) { 
                    self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)              
                }) 
        },
        getServerPath(name) {
            if(this.isBoard)
            {
                return this.url + 'uploads/'+this.customer.id+'/board/' + name;
            }
            else
            {
                return this.url + 'uploads/'+this.customer.id+'/work-groups/'+this.workGroupId+'/' + name;
            }
        },
        deleteFile(file)
        {
            var self = this;
            var url = this.isBoard ? 'board-documents/'+ file.id +'/delete' : 'work-group-documents/'+ file.id +'/delete';
            this.$communicator.post(url)
                .then(function(data)
                {
                    self.getFiles();
                    self.updateChat.timeToUpdate = true;
                    message.setSuccessMessage("Toppen! Filen är nu borttagen.");
                })
                .fail(function(error) {                  
                })
        }
    },
    computed: { 
          url() { return config.urlToServer },
          customer() {return this.$store.state.customer; },
    },
    watch: {
        workGroupId(newvalue){
            this.getFiles();
        }
    }
};
</script>
<template>          
    <div class="float-right">
        <button id="List-sort-btn" class="btn" @click="addDocument = true">Lägg till ett dokument</button>
                
    </div>
    <h2>Filer</h2>
    <div class="margin-top-15">
        <div v-for="(file,index) in files" class="Documents-doc">
            <p class="Documents-doc-remove"><i class="fa-solid fa-trash"  v-tooltip="'Ta bort filen'"  @click="deleteFile(file)"></i></p>
            <a :href="getServerPath(file.name)" target="_blank">
                <div v-if="file.file_type == 'pdf'">
                    <i class="fa-solid fa-file-pdf"></i><br>
                </div>
                <div v-else-if="file.file_type == 'docx' || file.file_type == 'doc'">
                    <i class="fa-solid fa-file-word"></i><br>
                </div>
                <div v-else-if="file.file_type == 'xlsx' || file.file_type == 'xls'">
                    <i class="fa-solid fa-file-excel"></i><br>
                </div>
                <div v-else-if="file.file_type == 'txt'">
                    <i class="fa-solid fa-file-lines"></i><br>
                </div>
                <div v-else>
                    <i class="fa-solid fa-image"></i><br>
                </div>
                {{ file.file_type }}
                {{ file.name }}
            </a>
        </div>
    </div>
    <div v-if="files.length == 0">
        Det finns inga filer uppladdade
    </div>
    <div v-if="addDocument">
        <PopUp>
            <UploadDocuments :type="isBoard ? 'board' : 'workGroup'" :id="workGroupId"></UploadDocuments>                     
            <button class="btn float-right" @click="getFiles(true)">Klar</button>
        </PopUp>
    </div>
  </template>

<style scoped>
    .Documents-doc
    {
        width: 150px;
        height: 200px;
        display: block;
        text-align: center;
        font-size: 15px;
        float:left;
        position: relative;

    }

    .Documents-doc i{
        font-size: 50pt;
        padding: 10px 0px;
    }

    .Documents-doc-remove
    {
        position:absolute;
        top: 0px;
        right: 10px;
    }
    
    .Documents-doc-remove i
    {       
        font-size: 16px;
        color: white;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
</style>
