<script>
  import config from "@/helpers/config";
  export default {
      data() {
          return {
            selectedCategory: null
          };
      },
      created() {
        this.$cache.createCache('documents','document-categories/')
      },
      methods: {

      },
      computed: {
        documentCategories() { return this.$store.state.documents == null ? [] : this.$store.state.documents },
        url() { return config.urlToServer },
        customer() {return this.$store.state.customer; },
      }
    };
</script>
<template>          
    <div class="margin-top-15">
        <div v-if="selectedCategory == null" class="Documents-wrapper">
            <div v-for="category in documentCategories" class="Documents-folder" @click="selectedCategory = category">
                <i class="fa-solid fa-folder"></i>
                <p>{{category.name}} ({{category.documents.length}} filer)</p>
            </div>
        </div>
        <div v-else>
            <h2>{{ selectedCategory.name }}</h2>
            <div class="overflow-auto Documents-wrapper">
                <a v-for="file in selectedCategory.documents" class="Documents-doc" v-hover :href="url + 'uploads/'+customer.id+'/documents/' + file.name" :key="file.id" target="_blank">
                    <div v-if="file.file_type == 'pdf'">
                        <i class="fa-solid fa-file-pdf"></i><br>
                    </div>
                    <div v-else-if="file.file_type == 'docx' || file.file_type == 'doc'">
                        <i class="fa-solid fa-file-word"></i><br>
                    </div>
                    <div v-else-if="file.file_type == 'xlsx' || file.file_type == 'xls'">
                        <i class="fa-solid fa-file-excel"></i><br>
                    </div>
                    <div v-else-if="file.file_type == 'txt'">
                        <i class="fa-solid fa-file-lines"></i><br>
                    </div>
                    <div v-else>
                        <i class="fa-solid fa-image"></i><br>
                    </div>
                    {{ file.name }}
                </a>
            </div>
            <div class="g-100"> 
            <button class="btn float-right" @click="selectedCategory = null">Tillbaka</button>
            </div>
        </div>
          
       
    </div>
    <div v-if="documentCategories.length == 0">
        Det finns inga filer uppladdade
    </div>
  </template>

<style scoped>
    .Documents-doc
    {
        width: 150px;
        border: 0px solid green;
        display: block;
        text-align: center;
        font-size: 15px;
        padding: 10px 20px;
        line-break: anywhere;
        text-wrap: balance;
    }

    .Documents-doc i{
        font-size: 50pt;
        padding: 10px 0px;
    }

    .Documents-wrapper {
        display:flex;
        flex-wrap: wrap; 
        justify-content: center;
    }


    .Documents-folder {
        width: 150px;
        padding: 10px 20px;
        cursor:pointer;
        text-align: center;     
    }
    
    .Documents-folder i {
        font-size: 50pt;
    }
    
    .Documents-folder i:hover {
        opacity: 0.7;
    }

    @media only screen and (max-width: 1000px) {

        .Documents-doc,
        .Documents-folder {
            width: 80px; 
            font-size: 14px;
        }
        
        .Documents-doc i,
        .Documents-folder i {
            font-size: 36pt;
        }
        
    }
</style>
