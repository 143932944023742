<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import UserInstruction from '@/components/UserInstruction.vue'
import message from '@/helpers/message'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        }        
        this.$cache.createCache('news', 'news/');
        this.$cache.createCache('accountSettings', 'account-settings/') 
    },
    data() {
        return {               
            sorting: [
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, message: '', include_in_public_site: false, image_ids: [] };
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('news/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getNews();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getNews()
        {
            this.editItem = null;
            this.$cache.updateCache('news', 'news/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },
        news() {   return this.$store.state.news != undefined ? this.$store.state.news : [] },
        columns() { 
            var cols = [                             
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},
                { name: 'message', displayName: 'Meddelanden', width: 1400, hideFilter: true, isTextEditorFormat: true},             
            ] 

            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                cols.splice(4,0,{ name: 'include_in_public_site', isBoolean: true, displayName: 'Visas även på den publika sidan', width: 250});
            }
            return cols;
        },        
        form() {
            var form = [                   
                { sections: [
                    { sectionName: 'Lägg till aktuellt meddelande', classes: "g-100", inputs: [
                        { type: 'textEditor', prop: 'message', title: 'Meddelande', classes: "g-100", required: true },                        
                        ]
                    },
                    { sectionName: 'Lägg till bilder', classes: "g-100", inputs: [
                        { type: 'images', prop: 'image_ids', title: 'Klicka på den bild/de bilder du vill visa i meddelandet', classes: "g-100" },                        
                        ]
                    },
                    { sectionName: 'E-mail utskick', classes: "g-100", inputs: [
                    { type: 'switch', prop: 'send_email', title: 'Skicka även detta meddelandet till alla medlemmars E-mail.', classes: "g-100" }                        
                        ]
                    }]
                }];
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                form[0].sections.push({ sectionName: 'Visa även meddelandet på den publika sidan', classes: "g-100", inputs: [                        
                    { type: 'switch', prop: 'include_in_public_site', title: '', classes: "g-100" }                        
                ]});
            }

            return form;
        }
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative" v-if="accountSettings != null">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till ett meddelande</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Aktuellt från styrelsen'"></ToAdmin>
            <h2>Aktuella meddelanden från styrelsen</h2>
           
                <div v-if="editItem != null">
                    <PopUp>
                    <Form :form="form" :item="editItem" :url="'news/'" :getItemFunction="getNews" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List :items="news"
                :columns="columns" 
                :noOfFrozenColumns="4" 
                :deleteFunction="delete" 
                :isEditing="editItem != null" 
                :nameOfItems="'meddelanden'" 
                :sorting="sorting" 
                :backgroundColor="this.$colors.list.backgroundColor" 
                :borderColor="this.$colors.list.borderColor">
             </List>        
            <div v-if="news.length == 0"> Du har inte lagt upp några aktuella meddelanden</div>
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>