<script>
import config from "../../helpers/config";
import PopUp from '@/components/PopUp.vue';
import message from '@/helpers/message'

export default {
  components: {
    PopUp
  },
  props: ['type'],
  data() {
    return {
      openPopup: false,
      file: null,
      data: [],
      isUploading: false
    };
  },
  methods: {
    handleFileUpload(event) {
        this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
          this.error = "Vänligen välj en fil.";
          return;
      }

      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.file);

      var self = this;
      $.ajax({
        url: config.urlToApi + this.type.url + '-preview/',
        type: 'post',
        data: formData,
        processData: false,  // Important!
        contentType: false,  // Important!           
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
        },
        success: function(response) {
            self.data = response;   
            self.isUploading = false;         
        },
        error: function(xhr, status, err) {
          self.isUploading = false;
          if (status === 'abort') {
            console.log('Request canceled', err);
          
          } else {
            
          }
        },
      });
    },
    closePopup() {
      this.data = [];
      this.openPopup = false;
    },
    saveAndSendMail() {
      
    },
    save(url, successMessage) {
      var self = this;
      this.isUploading = true;
      this.$communicator.post(url + '-save/', this.data)
        .then(function(data)
        {       
          message.setSuccessMessage(successMessage);
          if(self.type.isMemberUpload)
          {
            self.$cache.updateCache('members', 'members/');
          }
          else if(self.type.isStatutesUpload)
          {
            self.$cache.updateCache('statutes', 'statutes/');
          }
          else if(self.type.isRulesOfProcedureUpload)
          {
            self.$cache.updateCache('rulesOfProcedure', 'rules-of-procedure/');
          }

          self.closePopup()
          self.isUploading = false;
        })
          .fail(function(error) {
            self.isUploading = false;
            
        });
    }
  }
};
</script>
<template>
  <button class="btn btn-gray" @click="openPopup = true"><i class="fa-solid fa-file-excel"></i> &nbsp;{{type.importButtonText }}</button>
  <PopUp v-if="openPopup">
    <button class="btn float-right" @click="openPopup = false">Stäng</button>
    <h2>Importera från Microsoft Excel</h2>
    <p><span v-if="type.isMemberUpload">
        Att importera ett befintligt medlemsregister är ett bra alternativ för att få in alla föreningens medlemmar snabbt. 
        Börja med att ladda hem mallen och fyll den med medlemsdata.
      </span>
      <span v-else> Börja med att ladda hem mallen och fyll den med eran data.</span>
      Därefter laddar ni upp mallen i importformuläret nedan.
    </p>
    <div class="overflow-auto margin-top-15">
      <div class="g-50">
        <a v-if="type.isMemberUpload" class="UploadExcel-template-btn border-color wow-color" href="/medlemsmallen.xlsx" target="_blank">
          <i class="fa-solid fa-file-excel"></i> &nbsp; Klicka här för att ladda hem Importmallen
        </a>
        <a v-if="type.isStatutesUpload" class="UploadExcel-template-btn border-color wow-color" href="/stadgarmallen.xlsx" target="_blank">
          <i class="fa-solid fa-file-excel"></i> &nbsp; Klicka här för att ladda hem Importmallen
        </a>
        <a v-if="type.isRulesOfProcedureUpload" class="UploadExcel-template-btn border-color wow-color" href="/ordningsreglermallen.xlsx" target="_blank">
          <i class="fa-solid fa-file-excel"></i> &nbsp; Klicka här för att ladda hem Importmallen
        </a>
      </div>
      <div class="g-50">
         <form @submit.prevent="uploadFile">
            <input type="file" @change="handleFileUpload" accept=".xlsx" style="width: 400px;" />
            &nbsp;
            <button type="submit" class="btn" style="padding: 10px 20px;" :disabled="isUploading">Ladda upp</button>
        </form>
      </div>
    </div>
    <div class="margin-top-25" v-if="data.length > 0" v-fade-in>
      
      <h2>Följande data har lästs in från excelfilen</h2>
      <div class="UploadExcel-table-content wow-color wow-color-bg">
        <table>
          <tr>
            <th v-for="header in type.tableHeaders">
              {{ header }}
            </th>
          </tr>
          <tr v-for="row in data">
            <td v-for="(header, index) in type.tableHeaders">
                {{Object.values(row)[index] }}          
            </td>
          </tr>
        </table>
      </div>
      <p class="margin-top-15">Om detta är korrekt klickar du på {{ type.isMemberUpload ? '"Spara och skicka ut välkomstmail" eller "Spara"': '"Spara"' }}. Annars ändra i mallen och ladda upp på nytt</p>
      <button v-if="type.isMemberUpload" class="float-right btn btn-gray"  :disabled="isUploading" @click="save('upload-members-send-and', 'Toppen! Medlemmarna är nu tillagda och alla kommer få var sitt välkomstmail!')">Spara och skicka ut välkomstmail</button>
      <button class="float-right btn" @click="save(type.url, type.successMessage)" :disabled="isUploading">Spara</button>
    </div>
    
  </PopUp>
</template>
<style scoped>
.UploadExcel-template-btn
{
    width: fit-content;
    padding: 8px 20px;
    border-width: 2px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 3px;
    display: block;
    color: black;
}

.UploadExcel-table-content {
  
    padding: 15px;
    border-radius: 4px;
}
</style>
