<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        
        this.$cache.createCache('workGroups', 'work-groups/');
        this.getInvitations();
       
    },
    data() {
        return {  
            invitations: [], 
            columns: [                             
                { name: 'view_icon', displayName: '.', rowIcon: true, title:'Gå till inbjudan', align:'center', function: this.goToInvitation, width: 30, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, isNotDeletable: 'is_sent', align:'center', width: 60, hideFilter: true, hideIfEdit: true, title: 'Bara för de som inte skickats iväg.'},
                { name: 'title', displayName: 'Titel', width: 300, hideFilter: true},
                { name: 'description', displayName: 'Beskrivning', width: 500, hideFilter: true, isTextEditorFormat: true},
                { name: 'recipients_in_list', displayName: 'Mottagare', width: 400, hideFilter: true},
                { name: 'is_sent', displayName: 'Har skickats iväg', width: 140, hideFilter: true, isBoolean: true, align: 'center'},
                { name: 'created_date_formatted', displayName: 'Skapad', width: 150, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            dropdowns: [{ prop: 'expired', value: false, availableItems: [{ id: false, name: 'Visa kommande inbjudan'}, { id: true, name: 'Visa avslutade inbjudan'}, { id: null, name: 'Visa alla inbjudan'  }] }]
        }
    },   
    methods:
    {
        getInvitations()
        {
            var self = this;
            this.$communicator.get('admin-invitations/') 
                .then(function(data)
                {
                    self.invitations = data;              
                })
                .fail(function(error) {                  
                }) 
            
        },
        goToInvitation(item)
        { 
            if(item.is_sent)
            {           
                this.$router.push('/admin/invitations/' + item.id + '/view');
            }
            else
            {
                this.$router.push('/admin/invitations/' + item.id);

            }
        },
        add()
        {
            this.$router.push('/admin/invitations/add');
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('admin-invitations/' + id + '/delete')
                .then(function(data)
                {                 
                   self.getInvitations();
                    message.setSuccessMessage();
                   
                })
                .fail(function(error) {                  
                })  
        }        
    },
    computed:
    {
        user() {return this.$store.state.user; },     
        users() {return this.$store.state.users; },      
        workGroups() {return this.$store.state.workGroups; },      
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en inbjudan</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Inbjudan'"></ToAdmin>
            <h2>Inbjudan</h2>
            <List 
                :items="invitations" 
                :columns="columns" 
                :noOfFrozenColumns="3" 
                :deleteFunction="delete" 
                :nameOfItems="'inbjudan'" 
                :sorting="sorting" 
                :backgroundColor="this.$colors.list.backgroundColor" 
                :borderColor="this.$colors.list.borderColor"
                :dropdowns="dropdowns"></List> 

            <div v-if="invitations.length == 0">
                Klicka på knappen 'Lägg till en inbjudan' för att skapa din första inbjudan.
            </div>       
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>