<script>
import UserMessageSender from '../components/UserMessageSender.vue'
import YesNoPopUp from '@/components/YesNoPopUp.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import notifications from '@/helpers/notifications'
export default {
    components:{ UserMessageSender, YesNoPopUp, Form, PopUp },
    created() {
        this.$cache.createCache('forums', 'forums/') 
        this.forumId = this.$route.params.id;
        this.getThread();
    },
    data() {
        return {   
            forumId: null,
            startPost: null,
            removePost: null,
            removeReply: null,
            addPost: null,
            editPost: null,
            editStartPost: null,
            posts: [],
            customer: this.$store.state.customer,
            userMessage: null,
            form: [{ sections: [{ sectionName: 'Svara', classes: "g-100", inputs: [ 
                             { type: 'textEditor', prop: 'body', title: 'Meddelande', classes: "g-100", required: true },
                            ]
                        }]
                    }],
            startPostForm: [{ sections: [{ sectionName: 'Ändra inlägget', classes: "g-100", inputs: [ 
                             { type: 'text', prop: 'subject', title: 'Ämne', classes: "g-100", required: true },
                             { type: 'textEditor', prop: 'body', title: 'Meddelande', classes: "g-100", required: true },
                            ]
                        }]
                    }],
            postForm: [{ sections: [{ sectionName: 'Ändra svaret', classes: "g-100", inputs: [                          
                             { type: 'textEditor', prop: 'body', title: 'Meddelande', classes: "g-100", required: true },
                            ]
                        }]
                    }],
        }  
    },
    methods:
    {
        getThread()
        {
            var self = this;
            this.$communicator.get('forums/' + this.forumId + '/thread/' +  this.$route.params.threadId)
                .then(function(data)
                {
                    self.editPost = null;
                    self.editStartPost = null;
                    self.addPost = null;
                    self.startPost = data[0];
                    self.posts = data.filter(x => x.id != self.$route.params.threadId);
                    if(self.notifications.length > 0)
                    {
                        notifications.getNotifications();
                    }
                })
                .fail(function(error) {
                    self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)   
                });   
        },
        add()
        {
            this.addPost = { id: 0, body: '', forum_id: this.forumId, reply_to_post_id: this.startPost.id, is_work_group: this.forum.work_group_id > 0 };
        },      
        removeThread()
        {
            var self = this;
            this.$communicator.post('posts/' + this.removePost.id + '/delete')
                .then(function(data)
                {
                    self.$cache.updateCache('forums', 'forums/');  
                    message.setSuccessMessage('Ditt inlägg är nu raderat');
                    self.$router.push('/forum/' + self.forumId);      

                })
                .fail(function(error) {                  
                })   
        },
        deleteReply()
        {
            var self = this;
            this.$communicator.post('reply-posts/' + this.removeReply.id + '/delete')
                .then(function(data)
                {
                    message.setSuccessMessage('Ditt svar är nu raderat');
                    self.removeReply = null;
                    self.getThread();
                })
                .fail(function(error) {                  
                })   
        },
        cancelRemove()
        {
            this.removePost = null;
            this.removeReply = null;
        },
        sendUserMessage(post)
        {   
            this.userMessage = { id: 0, to_user_id: post.created_by, subject: 'Ang ditt inlägg: ' + post.subject, message: '', is_read: false, toUserName: post.created_by_user.full_name }
        },
        closeUserMessageSender()
        {
            this.userMessage = null;
        }
    },
    computed:
    {
        user() { return this.$store.state.user; },
        forumCategory() { return this.$store.state.forums != null ?  this.$store.state.forums.find(x => x.id == this.forumId).category : ''; },
        forum() { return this.$store.state.forums != null ?  this.$store.state.forums.find(x => x.id == this.forumId) : ''; },
        notifications() {   return this.$store.state.notifications != null ? this.$store.state.notifications : []; },
    }
}
</script>
<template>
    <main class="opacity-white-bg">
        <section v-if="startPost != null">   
            <div class="breadcrumbs">
                    <router-link :to="{ name: 'forum'}">
                     forumet
                    </router-link> 
                    &nbsp; >>  &nbsp;
                    <router-link :to="{ name: 'forumCategory', params: { id: forumId }}">{{ forumCategory }}</router-link>
                    &nbsp; >>  &nbsp;
                    <b>{{ startPost.subject }}</b>
                </div>                     
            <div class="post-content" style="background: white">             
                <div style="overflow: auto">
                    <h4><span class="font-color">{{ startPost.subject }} </span>
                        <span v-if="startPost.created_by_user.id == user.id">
                            <i class="fa-solid fa-pencil" v-tooltip="'Ändra inlägget'" @click="editStartPost = startPost"></i>
                            <i class="fa-solid fa-trash-can" v-tooltip="'Ta bort hela inlägget (inklusive svaren)'" @click="removePost = startPost"></i>
                        </span>
                    </h4>                  
                    <p v-html="startPost.body"></p> 
                    <div class="float-right ForumThread-sent-by">
                        <i>Inlagt <span v-date="{date: startPost.created_date, type: 'dateAndTime'}"></span>                                 
                        av {{ startPost.created_by_user.full_name }}</i>
                    </div>
                </div>
            </div>
          
            <div v-for="post in posts" class="post-content" :class="post.created_by_user.id == user.id ? 'post-content-own-reply' : ''">
                <p v-html="post.body"></p>
                <div class="ForumThread-sent-by">
                    <span v-if="post.created_by_user.id == user.id">
                        Du svarade, 
                    </span>
                    <span v-else>
                        Svar från {{ post.created_by_user.full_name }},  
                    </span> 
                    <span v-date="{date: post.created_date, type: 'dateAndTime'}"></span>
                    <span v-if="post.created_by_user.id == user.id">
                        <i class="fa-solid fa-pencil" v-tooltip="'Ändra inlägget'" @click="editPost = post"></i>
                        <i class="fa-solid fa-trash-can" v-tooltip="'Ta bort ditt inlägg'" @click="removeReply = post"></i>
                    </span>
                    &nbsp;
                    <i class="fa-solid fa-message" v-if="!customer.disable_user_messages && post.created_by != user.id" @click="sendUserMessage(startPost)" v-tooltip="'Skicka privat meddelande till '  + post.created_by_user.full_name"></i>
                </div>
                <!-- <hr/> -->
            </div>
            <div class="text-right">
                <button class="btn mobile-100w-btn btn-gray" v-if="!customer.disable_user_messages && startPost.created_by != user.id" @click="sendUserMessage(startPost)">Skicka privat meddelande till  {{ startPost.created_by_user.full_name }}</button>
                <button @click="add()" class="btn mobile-100w-btn">Svara</button>
            </div>
        </section>  
        <div v-if="addPost != null">
            <PopUp>
                <Form :form="form" :item="addPost" :url="'posts/'" :getItemFunction="getThread" :inEditMode="true"></Form>
            </PopUp>
        </div>   
        <div v-if="editPost != null">
            <PopUp>
                <Form :form="postForm" :item="editPost" :url="'posts/'" :getItemFunction="getThread" :inEditMode="true"></Form>
            </PopUp>
        </div>   
        <div v-if="editStartPost != null">
            <PopUp>
                <Form :form="startPostForm" :item="editStartPost" :url="'posts/'" :getItemFunction="getThread" :inEditMode="true"></Form>
            </PopUp>
        </div>   
    </main>
    <div v-if="userMessage != null">
        <UserMessageSender :userMessage="userMessage" :closeUserMessageSender="closeUserMessageSender"></UserMessageSender>
    </div> 
    <div v-if="removePost != null">
        <YesNoPopUp :yes="removeThread" :no="cancelRemove" :text="'Är du säker på att du vill ta bort?'"></YesNoPopUp>       
    </div>
    <div v-if="removeReply != null">
        <YesNoPopUp :yes="deleteReply" :no="cancelRemove" :text="'Är du säker på att du vill ta bort?'"></YesNoPopUp>       
    </div>
</template>

<style scoped>
    .ForumThread-sent-by{
        font-size: 13px;
        font-style: italic;
        margin-right: 2px;
    }

    .post-content
    {
        margin-top: 10px;
        margin-bottom: 15px;
        overflow:auto;
        padding:10px 15px;
        border-radius: 4px;
        background: rgba(255,255,255,0.6)
    }

    .post-content-own-reply
    {
        background: wheat !important;
        text-align: right;
    }

    .fa-pencil
    {
        padding: 0px 10px;
    }

    .fa-solid{
        cursor: pointer;
    }
    

    h4{
        padding-bottom: 8px;
       border-bottom:1px solid #ccc;
       overflow: auto;
    }

    hr {
        border:0px;
        border-top:1px solid #ccc;
        height:1px;
        background: none;
    }
</style>