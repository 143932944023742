<script>
import PopUp from '@/components/PopUp.vue'
import UploadPictures from '@/components/UploadPictures.vue'
import message from '@/helpers/message'
import ToAdmin from '@/components/ToAdmin.vue'
import config from "@/helpers/config"
import Switch from '@/components/Form/Switch.vue'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ PopUp, UploadPictures, ToAdmin, Switch, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.getPublicPage();
        
        this.$cache.createCache('images', 'images/');
    },
    data() {
        return {             
            showUploadImagesPopUp: false,
            highlightedImage: null,
            height: $(window).height() - 130,
            publicPage: null,
            isLoading: true
        }
    },   
    methods:
    {
        download(item)
        {
            window.open(this.url + 'uploads/'+this.customer.id+'/'+ item.name);
        },
        add()
        {
            this.showUploadImagesPopUp = true;
        },
        getPublicPage()
        {
            var self = this;
            this.$communicator.get('admin-public-page/')
                .then(function(data)
                {                 
                    self.publicPage = data.public_page;
                    self.isLoading = false;
                })
                .fail(function(error) {                  
                })  
        },     
        deleteImage(id)
        {
            var self = this;
            this.$communicator.post('images/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getImages();
                    message.setSuccessMessage();
                })
                .fail(function(error) {     
                    console.log(error); 
                    var location = '';
                    switch(error.responseJSON.detail)
                    {
                        case 'community_image':
                            location = 'på föreningssidan';
                            break;
                        case 'logo_image':
                            location = 'som logo';
                            break;
                        case 'welcome_image':
                            location = 'på startsidan';
                            break;
                        case 'image_gallery_image':
                            location = 'i bildgalleriet';
                            break;
                        case 'contact_image':
                            location = ' på kontaktsidan';
                            break;
                    }

                    message.setInfoMessage('Bilden används på den publika sidan, närmare bestämt '+ location + '. Gå igenom den publika sidan och se till att inga sidor använder bilden innan du försöker igen.');            
                })  
        },     
        getImages()
        {
            this.showUploadImagesPopUp = false;
            this.$cache.updateCache('images', 'images/');
        },
        includeInGallery(image, include)
        {
            image.include_in_image_gallery = include;
            var self = this;
            this.$communicator.post('images/include-in-image-gallery/', image )
                .then(function(data)
                {                 
                    
                })
                .fail(function(error) {                  
                })  
        },
        setAsBackgroundImage(image)
        {
            var self = this;
            this.$communicator.post('images/set-as-background-image/', image)
                .then(function(data)
                {
                    self.$cache.updateCache('customer', 'customers/');
                    var url = 'url("' + self.url + 'uploads/'+ data.id+'/images/'+ data.background_image_name + '")'
                    $("body").css("background-image", url);
                    localStorage.setItem("samfallifyBgImg", url);  
                })
                .fail(function(error) {                  
                })  

        },
        removeAsBackgroundImage()
        {
            var self = this;
            this.$communicator.post('images/remove-background-image/')
                .then(function(data)
                {
                    localStorage.removeItem("samfallifyBgImg");
                    window.location.reload();
                })
                .fail(function(error) {                  
                })
        }
    },
    watch:
    {
        images: {
            handler(images){
                
            },
            deep: true
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        customer() {return this.$store.state.customer; },
        url() { return config.urlToServer },
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  }      
    }
}
</script>
<template>
    <div v-fade-in v-if="!isLoading">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <ToAdmin :section="'Bildbanken'"></ToAdmin>          
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Ladda upp bilder</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <h2>Bildbanken</h2>       
            <div v-for="image in images" class="AdminImageVault-img-area">
                <div class="AdminImageVault-toggle-image-gallery">
                    <i class="fa-solid fa-trash" v-tooltip="'Ta bort bild'" @click="deleteImage(image.id)"></i>&nbsp;
                    <i v-show="customer.background_image_id == image.id" style="color: greenyellow" class="fa-solid fa-panorama" v-tooltip="'Använd ej som bakgrundsbild i appen'" @click="removeAsBackgroundImage()"></i>
                    <i v-show="customer.background_image_id != image.id" class="fa-solid fa-panorama" v-tooltip="'Välj som bakgrundsbild i appen'" @click="setAsBackgroundImage(image)"></i>
                    &nbsp;
                    <span>
                        <i class="fa-solid fa-images" v-show="!image.include_in_image_gallery" v-tooltip="'Klicka här för att inkludera den i bildgalleriet'" @click="includeInGallery(image, true)"></i>
                        <i class="fa-solid fa-images" v-show="image.include_in_image_gallery" style="color: greenyellow" v-tooltip="'Visas i bildgalleriet (Klicka här för att ta bort den från galleriet)'" @click="includeInGallery(image, false)"></i>
                    </span>
                </div>
                <img :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ image.name" @click="highlightedImage = image" />
                <div class="overflow-auto">
                    
                    <p>
                        Namn: {{ image.name }}<br>
                        Uppladdad: {{ image.uploaded_date }}
                    </p>
                </div>
            </div>
            <div v-if="images.length == 0">
                Du har inte laddat upp några bilder ännu
            </div>
            <div v-if="showUploadImagesPopUp">
                <PopUp>
                    <UploadPictures></UploadPictures>                     
                    <button class="btn float-right" @click="getImages">Klar</button>
                </PopUp>
            </div>           
            <div v-if="highlightedImage != null">
                <PopUp :width="'95%'" :bgColor="'black'">
                    <div class="AdminImageVault-highlighted-image">
                        <button class="btn" @click="highlightedImage = null">Stäng</button>
                        <img :src="url + 'uploads/'+ customer.id+'/images/'+ highlightedImage.name"  :style="{'max-height': height + 'px'}" />                    
                    </div>
                </PopUp>
            </div>           
        </main>
    </div>
</template>
<style scoped>
    .AdminImageVault-img-area
    {
        float: left;        
        height: 240px;
        background: #dbba73;
        border: 1px solid #dbba73;
        color: white;
        font-size: 11px;
        position: relative;

    }
    .AdminImageVault-toggle-image-gallery
    {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 22px;
        color: #ddd;
        cursor: pointer;    
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
    
    .AdminImageVault-toggle-image-gallery i
    {       
        color: #ddd;  
    }

    .AdminImageVault-img-area img
    {
        height: 200px;
        cursor: pointer;
    }

    .AdminImageVault-img-area p
    {
        margin: 4px 10px;
        line-height: 15px;
    }

    .AdminImageVault-highlighted-image
    {
        text-align: center;
        position: relative;
    }
    .AdminImageVault-highlighted-image img
    {
        max-width: 100%;
    }
    .AdminImageVault-highlighted-image button
    {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .AdminImageValue-trash
    {
        float: right;
        font-size: 20px;
        padding:5px 10px;
    }
</style>