<template>
    <section>
        boa logga<br>
        Sidan du försöker nå finns inte. :(
    </section>
</template>
<style scoped>
    section{
        margin-top: 40px;
        text-align: center;
    }
</style>