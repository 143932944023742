<script>
import ToAdmin from '@/components/ToAdmin.vue'
import UserInstruction from '@/components/UserInstruction.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import YesNoPopUp from '@/components/YesNoPopUp.vue';
export default {
    components:{ ToAdmin, UserInstruction, Form, PopUp, YesNoPopUp },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        }        
        this.$cache.createCache('documents', 'document-categories/');
    },
    data() {
        return {  
            documentCategories: [], 
            editItem: null, 
            deleteItem: null,    
        }
    },   
    methods:
    {         
        goTo(category)
        {
            this.$router.push({ name: 'adminDocuments', params: { id: category.id } });
        },
        add()
        {
            this.editItem = { id: 0, name: ''};
        },
        getCategories()
        {
            this.editItem = null;
            this.$cache.updateCache('documents', 'document-categories/');
        },
        deleteCategory()
        {
            var self = this;
            this.$communicator.post('document-categories/' + this.deleteItem.id + '/delete')
                .then(function(data)
                {   
                    self.deleteItem = null;      
                    self.getCategories();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        }, 
        cancel() {
            this.deleteItem = null;
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },  
        formTitle() { return this.editItem != null && this.editItem.id == 0 ? 'Lägg till en ny mapp' : 'Byt namn på mappen' }, 
        form() {
            return [                   
                    { sections: [
                        { sectionName: this.formTitle, classes: "g-100", inputs: [
                            { type: 'text', prop: 'name', title: 'Namn på mappen', classes: "g-100", required: true }                   
                            ]
                        }]
                    }];
        }, 
        documents() { return this.$store.state.documents == null ? [] : this.$store.state.documents }
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en ny mapp</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Dokument'"></ToAdmin>
            <h2>Dokument</h2>
            <div v-for="category in documents" class="AdminDocumentCategories-folder">
                <b class="fa-solid fa-pen" v-tooltip="'Byt Namn på mappen'" @click="editItem=category"></b>
                <b class="fa-solid fa-trash" v-if="category.documents.length == 0" style="margin-right: 20px;" v-tooltip="'Ta bort mappen'" @click="deleteItem = category"></b>
                <i class="fa-solid fa-folder" @click="goTo(category)"></i>
                <p>{{category.name}} ({{category.documents.length}} filer)</p>
            </div>     
        </main>
    </div>
    <div v-if="editItem != null">
        <PopUp>
            <Form :form="form" :item="editItem" :url="'document-categories/'" :getItemFunction="getCategories" :inEditMode="true"></Form>
        </PopUp>
    </div>
    <div v-if="deleteItem != null">
        hej
        <YesNoPopUp :yes="deleteCategory" :no="cancel" :text="'Är du säker på att du vill ta bort mappen?'"></YesNoPopUp>       
    </div>
</template>
<style scoped>
    .AdminDocumentCategories-folder {
      
        padding: 10px 20px;
        text-align: center;    
        float: left; 
        position: relative; 
    }
    
    .AdminDocumentCategories-folder i {
        font-size: 50pt;
        cursor:pointer;
    }
    
    .AdminDocumentCategories-folder b {
        cursor:pointer;
        position: absolute;
        right: 4px;
        top:4px;
    }
    .AdminDocumentCategories-folder b:hover,
    .AdminDocumentCategories-folder i:hover {
        opacity: 0.7;
    }
</style>