<script>
export default {
    props: ['width'],
    data(){
        return {
         
        }     
    }
}
</script>
<template>
<div class="Loading-bg" v-fade-in="200">
    <div>
        <img src="@/assets/imgs/loading_spinner.gif" /> <br>
        Laddar...  
    </div>
</div>
</template>

<style scoped>
    .Loading-bg
    {
        position: fixed;
        left: 0px;
        top: 0px;
        width:100%;
        height: 100vh;
        backdrop-filter: blur(10px);
        z-index:70010;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Loading-bg div{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;

    }


</style>