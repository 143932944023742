export default {
    getLinks() { return [{ viewName: 'home', url: '/', icon: 'fa-regular fa-paper-plane', text: 'Aktuellt', name: ['home']  },
            { viewName: 'community', url: '/community/', icon: 'fa-solid fa-users', text: 'Föreningen', name:['community']  },
            { viewName: 'forum', url: '/forum', icon: 'fa-regular fa-comments', text: 'Forumet', name:['forum', 'forumThread','forumCategory'] },
            { viewName: 'bookings', url: '/booking', icon: 'fa-solid fa-calendar-days', text: 'Bokningar', name:['bookings','bookingList','bookingDaysAdd','bookingHoursAdd']  },              
            { viewName: 'invitations', url: '/invitation', icon: 'fa-solid fa-handshake-simple', text: 'Inbjudan', name:['invitations', 'invitation']  },
            { viewName: 'imageGallery', url: '/image-gallery', icon: 'fa-solid fa-images', text: 'Bild-<br>galleriet', name:['imageGallery']  },
          ]
    },
    hideMenuPages: [
      'admin', 
      'adminNews',
      'adminInvitations', 
      'adminForums', 
      'adminBoardMembers', 
      'adminWorkGroups', 
      'adminMembers', 
      'adminBookings', 
      'adminDocuments',
      'adminInvitationsAdd',
      'adminInvitationsView',
      'adminStatutes',
      'adminRulesOfProcedure',
      'adminAccount',
      'adminPublic',
      'adminImageVault',
      'adminBookingsEdit',
      'adminBookingsAdd',
      'adminInvitationsEdit',
      'adminDocumentCategories'
  ]
  } 