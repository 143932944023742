import store from '../store'
import router from '../router'
export default {
  handleErrors(error, returnPath)
  {
    if(error.status == 401)
    {                  
      store.commit('setEntity', { entityName: 'user', entity: null })
      if(returnPath != "/")
      {
        router.push({ path: '/login', query: { returnpath: "/" + returnPath.split('/').pop() }})  
      } else
      {
        router.push({ path: '/login', query: { returnpath: returnPath }})  
      }           
    }
  }
}