<script>
    export default {
        props: ['selectedItemSysIDs', 'selectItems', 'title'],
        emits: ['update:selectedItemSysIDs'],
        data() {
            return {
                availableItems: [],
            }
        },
        methods: {
            move(item, isAdding) {
                item.selected = isAdding;
                var selectedItems = this.availableItems.filter(x => x.selected).map(x => x.id);
                this.$emit('update:selectedItemSysIDs', selectedItems); 
            },
            populateAvailableItems()
            {
                if(this.selectItems == undefined)
                {
                    this.availableItems = this.$store.state.categories
                        .filter(p => p.prop_name == this.prop)
                        .map(function(item) {
                            return {id: item.name, name: item.name, selected: self.selectedItemSysIDs.some(x => x == item.id) }
                        });
                }
                else if(this.selectItems == 'users')           
                {   
                    var self = this;  
                    this.availableItems =  this.$store.state.users                  
                        .map(function(item) {
                            return {id: item.id, name: item.full_name, selected: self.selectedItemSysIDs.some(x => x == item.id) }
                        });
                }
                else if(this.selectItems == 'workGroups')           
                {   
                    var self = this;   
                    this.availableItems =  this.$store.state.workGroups                  
                        .map(function(item) {
                            return {id: item.id, name: item.name, selected: self.selectedItemSysIDs.some(x => x == item.id) }
                        });
                }
            }        
        },
        created() {
            this.populateAvailableItems();         
        }
    }
</script>

<template>
    <div class="row">
        <div class="g-50">
            <ul style="margin-right:30px;" class="MultiSelect-ul">
                <li v-for="item in availableItems.filter(x => !x.selected)" class="MultiSelect-li" @click="move(item, true)">
                    {{ item.name }}
                </li>
            </ul>
        </div>
        <div class="g-50 position-relative">
            <i class="fa-solid fa-arrows-left-right"></i>
            <p>Följande {{ availableItems.filter(x => x.selected).length }} st ingår: </p>
            <ul style="margin-left:30px; margin-right: 10px;" class="MultiSelect-ul">
                <li v-for="item in availableItems.filter(x => x.selected)" class="MultiSelect-li" @click="move(item, false)">
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>
    
<style scoped>
ul{
    list-style: none;
    padding: 0px;
    height: 300px;
    overflow-y: scroll;
}
li{
    padding: 4px 10px;
    font-size: 14px;
    cursor: pointer;
}

p{
    position: absolute;
    top: -21px;
    left: 30px;
}

.fa-solid
{
    position: absolute;
    top: 150px;
    font-size: 16pt;
    left: -12px;
}
</style>