<script>
import Incoming from '../components/Incoming.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
export default {
    components: {Incoming, PopUp },
    data(){
        return {
            month: '',
            currentDate: null,
            noOfDays: null,
            today: new Date(),
            months: this.$isMobile ? 
                ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug','sept','okt', 'nov', 'dec']
                : ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
            days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
            daysInNumbers: [6,0,1,2,3,4,5],
            allDaysInMonth: [] ,
            allBookingTimes: null,           
            bookingItem: null,
            newBookingDays: { 
                id: 0,                
                start_date: null, 
                end_date: null, 
                comments: '', 
                booking_item_id: null,
                duration_time: null,
                duration_unit: null
             },
            indexOfStart: null,
            indexOfEnd: null,
            showSummary: false           
        }
    },
    created()
    {
        var self = this;     
        this.currentDate = new Date();
        this.$communicator.get('add-booking/' + this.$route.params.id)
        .then(function(data)
        {             
            self.bookingItem = data.booking;
            self.allBookingTimes = data.allBookings;
            self.renderCalenderMonth(self.currentDate);
        })
        .fail(function(error) {
            self.$apiErrorhandler.handleErrors(error, self.$route.fullPath) 
        });   
    },
    methods:
    {
        showPrevMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()-1);
            this.renderCalenderMonth(this.currentDate);
        },
        showNextMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()+1);
            this.renderCalenderMonth(this.currentDate);
        },
        renderCalenderMonth(date)
        {
            this.month = this.months[date.getMonth()];
            this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
            var dayInMonth = 1;
            var dayInNextMonth = 1;

            var list = [];
            this.allBookingTimes.forEach(function(time) {
                var date = new Date(time.formatted_start_date);
                for(var i = 0; i < (time.duration_time); i++)
                {
                    list.push(date.getDate() + i);
                }
            })

            //fill calender with days
            this.allDaysInMonth = [];
            for(var i = 0; i <= 41; i++)
            {
                if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                {
                   //calculate day date and if its sunday and if its today
                    var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);                  
                    var isToday = this.today.getFullYear() == dayDate.getFullYear() && this.today.getMonth() == dayDate.getMonth() && this.today.getDate() == dayDate.getDate();

                    var dateString = this.getDateString(dayDate);
                    var bookingStart = this.allBookingTimes.find(x => x.formatted_start_date == dateString);
                    var bookingEnd = this.allBookingTimes.find(x => x.formatted_end_date == dateString);                  
     
                    this.allDaysInMonth.push({ 
                        id: i, 
                        date: dayDate, 
                        dateString: dateString, 
                        day: dayInMonth, 
                        isCurrentMonth: true, 
                        isSunday: dayDate.getDay() == 0, 
                        isToday: isToday, 
                        bookingStart: bookingStart, 
                        bookingEnd: bookingEnd,
                        occupied: list.some(x => x == dayInMonth)
                      });

                      dayInMonth++;
                }
                else if(i < this.daysInNumbers[firstDay.getDay()])
                {
                    this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false, isToday: false });
                    
                } else{
                    this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false, isToday: false });                    
                }
            }

            //fill previous months visible days
            var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            for(var i = 41; i >= 0; i--)
            {
                if(this.allDaysInMonth[i].day  == 'prevMonth')
                {
                    this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                }
            }      
        },
        getDateString(date)
        {
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        },
        selectDate(date)
        { 
            if(!date.isCurrentMonth)
            {
                return;
            }

            message.removeMessage();
            if(this.newBookingDays.start_date == null)
            {
                if(date.occupied)
                {
                    message.setInfoMessage("Du kan inte välja ett datum som redan är bokat.");
                    return;
                }
                date.selected = true;
                this.newBookingDays.start_date = date.dateString + " " + this.bookingItem.day_available_start_time;
                this.indexOfStart = this.allDaysInMonth.indexOf(date);
            }
            else{               
                var indexOfEndDate = this.allDaysInMonth.indexOf(date);

                if(indexOfEndDate < this.indexOfStart)
                {
                    this.allDaysInMonth.forEach(function(d) {
                        d.selected = false;
                    });
                    date.selected = true;
                    this.newBookingDays.start_date = date.dateString + " " + this.bookingItem.day_available_start_time;
                    this.indexOfStart = this.allDaysInMonth.indexOf(date);
                    this.indexOfEnd = null;
                    this.newBookingDays.end_date = null;
                }
                else if(indexOfEndDate-this.indexOfStart >= this.bookingItem.duration_time+1)
                {
                    message.setInfoMessage("Du har valt en längre bokningstid än vad som är möjligt. Maximal bokningstid är " + this.bookingItem.duration_time + " " + this.bookingItem.duration_unit);
                }
                else if(indexOfEndDate == this.indexOfStart)
                {
                    message.setInfoMessage("Du kan inte välja samma slutdatum som startdatum.");
                }               
                else{

                    for(var i = this.indexOfStart; i < indexOfEndDate; i++)
                    {
                        if(this.allDaysInMonth[i].occupied)
                        {
                            message.setInfoMessage("Du kan inte boka över en annan bokning.");
                            return;
                        }
                    }

                    date.selected = true;
                    this.indexOfEnd = indexOfEndDate;
                    this.newBookingDays.end_date = date.dateString  + " " + this.bookingItem.day_available_end_time;
                    this.allDaysInMonth.forEach(function(date) {
                        date.selected = false;
                    });

                    for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                    {
                        this.allDaysInMonth[i].selected = true;
                    }
                }
            }
        },    
        emptyBookingValues()
        {
            this.newBookingDays.start_time = null;
            this.newBookingDays.end_time = null;
            this.newBookingDays.start_date = null;
            this.newBookingDays.end_date = null;
            this.indexOfStart = null,
            this.indexOfEnd = null;
            this.start_time = null;
            this.allDaysInMonth.forEach(function(d) {
                d.selected = false;
            });
        },
        save()
        {
            this.newBookingDays.booking_item_id = this.bookingItem.id;
            this.newBookingDays.duration_unit = this.bookingItem.duration_unit;
            this.newBookingDays.duration_time = this.indexOfEnd - this.indexOfStart;

            var self = this;
            this.$communicator.post('add-booking/', this.newBookingDays)
                .then(function(data)
                {                                    
                    message.setSuccessMessage();
                    self.$cache.updateCache('bookings', 'bookings/');
                    self.$cache.updateCache('incomingEvents', 'incoming-events/');
                    self.$router.push('/booking')  
                })
                .fail(function(error) {    
                    if(error.responseJSON.detail == 'already booked')
                    {
                        message.setValidationErrorMessage(["Du har redan en aktiv bokning registerad. Du kan endast ha en bokning registrerad i taget."]);  
                    }                 
                }) 
        }       
    },
    computed:
    {
        isDayBooking() { return this.bookingItem != null ?  this.bookingItem.duration_unit == 'Timmar' : false  }
    }
}
</script>
<template>
    <main class="opacity-white-bg" v-fade-in v-if="bookingItem != null">
        <section>
            <button class="btn margin-top-15 float-right" v-if="newBookingDays.end_date != null" @click="showSummary = true">Visa sammanfattning och slutför bokningen</button>
            <h2>Skapa bokning av {{ bookingItem.name }}</h2>
            <div class="g-100">            
                <h3>1. Välj startdatum: {{ newBookingDays.start_date  }}
                    <span v-if="newBookingDays.start_date != null"> &nbsp;&nbsp;<button class="btn" @click="emptyBookingValues">Välj nytt startdatum</button></span>
                </h3>
                <h3 :style="{'text-decoration': newBookingDays.start_date == null ? 'line-through' : 'none', 'color': newBookingDays.start_date == null ? '#ccc' : 'black'}">1. Välj slutdatum: {{ newBookingDays.end_date  }}</h3>
           
                <div class="row">
                    <div class="width33 month-link" @click="showPrevMonth">&lt; {{ currentDate.getMonth() == 0 ? 'december' : months[currentDate.getMonth()-1] }}  </div>
                    <div class="width33 month">{{ this.month }} {{ this.currentDate != null ? this.currentDate.getFullYear() : '' }}</div>
                    <div class="width33 text-right month-link" @click="showNextMonth">{{ months[currentDate.getMonth()+1] }} &gt;</div>
                </div>
                <div class="row days">
                    <div v-for="day in days" class="width14" :key="day">
                    {{ day }}               
                    </div>
                </div>   
                <div v-for="(day,index) in allDaysInMonth" :key="day.id" class="width14 float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '' }" :class="{'BookingAdd-today-date': day.isToday, 'wow-color': day.selected }" @click="selectDate(day)">
                    <div class="day-content">
                        <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }" class="day-number">{{ day.isToday ? day.day + ' (idag)' : day.day }}</i>  
                        <div v-if="day.bookingEnd != undefined" class="BookingDaysAdd-occupied-end"></div> 
                        <div v-if="day.bookingStart != undefined" class="BookingDaysAdd-occupied-start">
                            <p>{{day.bookingStart.created_by_full_name }}</p>
                        </div>
                        <div v-if="day.bookingStart == undefined && day.bookingEnd == undefined && day.occupied" class="BookingDaysAdd-occupied-whole-day"></div>                     
                    </div>             
                </div>  
                    
            </div>          
        </section>
        <div v-if="showSummary">
            <PopUp>
                <h2>Bokning av {{ bookingItem.name }}</h2>
                Starttid: {{ newBookingDays.start_date }}<br>
                Sluttid: {{ newBookingDays.end_date }}<br>
                <br>
                <label>Lägg till en kommentar (frivillig, kommer bara synas för dig)</label>
                <textarea v-model="newBookingDays.comments"></textarea>

                <div class="info BookingAdd-info-box">
                    <b style="text-transform: uppercase;">Läs igenom följande information innan du klickar på slutför bokningen:</b>
                    <div class="margin-top-15" v-html="bookingItem.information"></div>
                </div>
                <div class="margin-top-15 float-right">
                    <button class="btn btn-gray" @click="showSummary = false">Gå tillbaka och ändra i bokningen</button>
                    <button class="btn" @click="save">Slutför bokningen</button>
                   
                </div>
            </PopUp>
        </div>
    </main>
</template>
<style scoped>
    section{
        width: 100%;
        overflow: auto;
    }

    .row{
        overflow: auto;
    }

    .day-content
    {
        
        height: 120px;
        
    }
    
     .day-content:hover
    {
      
       cursor: pointer;
    } 

    .day-content p
    {
        font-size: 13px;
        background-color: rgb(245, 150, 150);
        padding:5px;
        margin:0px;
    }
    
    .row div{
        padding:10px 0px;

    }
    
    .dates {
        padding:4px 0px;
        border-right: 1px dashed #ddd;
   
        border-bottom: 1px dashed #ddd;

    }
    
    .month{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .month-link
    {
        cursor: pointer;
    }
    
    .days{
        text-align: center;
        font-weight: bold;
    } 
    
    .days div{
        border-right: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        
    }

    .days div:first-child{
        border-left: 1px dashed #ddd;
    }

    .day-number
    {
        display: block;
    }
    .row div{
        float: left;
    }

    .width14
    {
        width: calc(14.2% - 2px);
    }

    .BookingAdd-hour
    {
        border-top: 1px dashed #ddd;
        height: 30px;
        position: relative;
    }
    
    .BookingAdd-hour-content
    {
        margin-top:56px; 
    }

    .BookingAdd-today-date
    {
        background-color: #ddd;
    }
    .BookingAdd-booking-occupied
    {
        background-color: rgb(245, 150, 150);
        width: 80%;
        height: 30px;
        float: left;
        margin-top: 2px;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid red;
    }

    .BookingAdd-booking-selected
    {
        background-color: rgb(243, 235, 161);
        width: 80%;
        height: 30px;
        float: left;
        margin-top: 2px;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid  rgb(243, 235, 161);
    }

    .BookingAdd-booking-occupied p
    {
        margin: 10px;
    }

    .BookingAdd-hour-text
    {
        margin-top: -11px;
        width:45px;
        padding-bottom: 12px;
        background-color: white;
    }

    .BookingAdd-info-box
    {
        padding:15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
   
   textarea
   {
    height: 200px;
   }
   .BookingDaysAdd-occupied-start
   {
    background-color: rgb(245, 150, 150);
    width:70%;
    height:100px;
    margin-left: 30%;
   }
  
   .BookingDaysAdd-occupied-end
   {
    background-color: rgb(245, 150, 150);
    width:30%;
    height:100px;
    margin-left: -1px;
    float:left;
   }
   
   .BookingDaysAdd-occupied-whole-day
   {
    background-color: rgb(245, 150, 150);
    width:calc(100% + 2px);
    height:100px;
    margin-left:-1px;
   }


</style>