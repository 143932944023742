<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'

export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('accountSettings', 'account-settings/') 
        this.$cache.createCache('availableBookings', 'available-bookings/');
    },
    data() {
        return {             
            sorting: [
                { name: 'name', displayName: 'Namm' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ]  ,
            reoccuringBooking: null,   
            form: [                   
                { sections: [{ sectionName: 'Veckodag', classes: "g-100", inputs: [ 
                        { type: 'select', prop: 'dayInWeek', title: 'Välj veckodag', classes: "g-100", required: true, selectItems: 'daysInWeek' },                                      
                        ]
                    }, { sectionName: 'Den återkommande bokningens startdatum och slutdatum', classes: "g-100", inputs: [
                        { type: 'date', prop: 'from', title: 'Datum', startWithDateNow: true, title: 'Startdatum', classes: "g-50", required: true }, 
                        { type: 'date', prop: 'to', title: 'Datum', startWithDateNow: true, title: 'Slutdatum', classes: "g-50", required: true }, 
                        ]
                    },  { sectionName: 'Bokningens start och sluttid', classes: "g-100", inputs: [
                        { type: 'select', prop: 'start_time', title: 'Till klockan', classes: "g-50", required: true, selectItems: 'hours' },                       
                        { type: 'select', prop: 'end_time', title: 'Till klockan', classes: "g-50", required: true, selectItems: 'hours' },                       
                        ]
                    }
                    ],
                backgroundColor: this.$colors.form.backgroundColor,
                borderColor: this.$colors.form.borderColor
            }]     
        }
    },   
    methods:
    {
        edit(item)
        {
            this.$router.push('/admin/bookings/' + item.id);   
        },
        add()
        {
            this.$router.push('/admin/bookings/add');          
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('available-bookings/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getBookings();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getBookings()
        {
            this.$cache.updateCache('availableBookings', 'available-bookings/');
        },
        createReoccuringBookning(item)
        {
            this.reoccuringBooking = { from: null, to: null, dayInWeek: null, start_time: null, end_time: null, name: item.name }
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },
        availableBookings() { return this.$store.state.availableBookings != null ? this.$store.state.availableBookings : []; },
        columns() { 
            var cols = [                             
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'name', displayName: 'Namn', width: 200, hideFilter: false},
                { name: 'duration', displayName: 'Bokningens maxlängd', width: 200, hideFilter: true},
                { name: 'availability', displayName: 'Tillgänglig för bokning', width: 260, hideFilter: true},
                // { name: 'reoccuring', displayName: 'Skapa återkommande bokning', function: this.createReoccuringBookning, align:'center', icon: 'fa-solid fa-clock-rotate-left', width: 230, hideFilter: true},
                { name: 'information', displayName: 'Information', width: 800, hideFilter: false, isTextEditorFormat: true},
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},            
            ] 
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                cols.splice(4,0,{ name: 'include_in_public_site', isBoolean: true, displayName: 'Visas även på den publika sidan', width: 250});
            }
            return cols;
        }       
    }
}
</script>
<template>
    <div v-fade-in  v-if="accountSettings != null">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till ett bokningsobjekt</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Bokningar'"></ToAdmin>
            <h2>Hantera bokningar</h2>               
            <List :items="availableBookings" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :nameOfItems="'tillgängliga bokningar'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
        </main>
    </div>
    <PopUp v-if="reoccuringBooking != null" :bgColor="'rgb(250, 233, 197)'">
        <h2>Skapa återkommande bokning av {{ reoccuringBooking.name }}</h2>
        <Form :form="form" :item="reoccuringBooking" :url="'reoccuring-booking/'" :getItemFunction="getBookings" :inEditMode="true" :hideCancel="true"></Form>
        <button class="btn float-right btn-gray" @click="reoccuringBooking = null">Stäng</button>
    </PopUp>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>