import config from "./config";
export default {
    get(url, data)
    {
        return $.ajax({
            url: config.urlToApi + url,
            data: data,
            contentType: 'application/json',
            type: 'Get',
            dataType: 'json',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
            }
          });
    },

    post(url, data)
    {
       return $.ajax({
            url: config.urlToApi + url,
            data: JSON.stringify(data),
            contentType: 'application/json',
            type: 'Post',
            dataType: 'json',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
            }
          });
    },
    
    postFile(url, data)
    {
       return $.ajax({
            url: config.urlToApi + url,
            data: JSON.stringify(data),
            contentType: 'multipart/form-data',
            type: 'Post',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
            }
          });
    },

    login(data)
    {
        return $.ajax({
          type: "POST",
          url: config.urlToApi + 'login/',
          data: data
        });

    }
}