<script>

import Notification from '@/components/Notification.vue'
export default {
    components: {Notification },
    created() {   
        this.$cache.createCache('invitations','invitations/');  
    },
    data() {
        return { 
            selectedInvitation: null,         
            shortMonths: ['','jan', 'feb', 'mars', 'apr', 'maj', 'jun', 'jul', 'aug','sep','okt', 'nov', 'dec'],
            filter: { prop: 'expired', value: false, availableItems: [{ id: false, name: 'Visa kommande inbjudan'}, { id: true, name: 'Visa avslutade inbjudan'}] }
        }
    },
    methods:
    {
        edit(item)
        {
            this.$router.push('/invitation/' + item.id);  
        },
        changeFilter(value)
        {
            this.filter.value = value;
        }
    },
    computed: {
        invitations() {  return this.$store.state.invitations; },
        user() {return this.$store.state.user; },  
        notifications() {  return this.$store.state.notifications != null ? this.$store.state.notifications : []; },
    }
}
</script>
<template>
    <div v-fade-in v-if="invitations != null && user != null">
        <main class="opacity-white-bg">
            <section>
                <h2>Inbjudan</h2>
                
                <div v-if="invitations.length > 0" class="margin-top-15">
                    <div class="overflow-auto">
                        <div v-if="!this.$isMobile" class="float-right">       
                            <button class="btn mobile-100w-btn" v-for="availableItem in filter.availableItems" :class="availableItem.id == filter.value ? '' : 'btn-gray'" @click="changeFilter(availableItem.id)">{{ availableItem.name }}</button>
                        </div>
                        <p>Klicka på en inbjudan för att få mer information.</p>  
                    </div>
                    <div class="margin-top-15px">
                        <div v-for="invitation in invitations.filter(x => x.expired == filter.value)" :key="invitation.id" class="invitation-item" @click="edit(invitation)" :style="{ 'background-color': invitation.recipients.find(x => x.user_id == user.id).participate_background_color}">
                            <Notification :level="'invitation'" :levelName="invitation.id" :notifications="notifications" :color="'#d98df6'" :left="'2px'" :top="'2px'"></Notification>                       
                            <div class="">
                                <div class="date-circle">         
                                    <b>{{invitation.day}}</b>
                                    <br>
                                    <span>{{ shortMonths[invitation.month[0] == 0 ? invitation.month[1] : invitation.month] }}</span>
                                </div>
                            </div>
                            <div class="">
                                <b>{{ invitation.title  }}</b>

                                <div v-if="invitation.description.length > 79" v-html-to-text="invitation.description.substring(0,80) + '...'"></div>    
                                <div v-else v-html-to-text="invitation.description"></div>    
                            </div>
                            <div class="float-right">
                                <span v-if="!invitation.enable_answers">Inget svar behövs</span>
                                <span v-if="invitation.enable_answers">
                                    <span v-if="invitation.recipients.find(x => x.user_id == user.id).participate == null">Meddela din närvaro</span>
                                    <b v-else>{{ invitation.recipients.find(x => x.user_id == user.id).participate ? 'Du har tackat JA' : 'Du har tackat Nej' }}</b>
                                </span>  
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="invitations.length == 0">
                    Du har inga kallelser alls! Dags o njuta av livet <span style="font-size: 30pt;">&#127870;</span>
                </div>
            </section>
            <section v-if="this.$isMobile" class="margin-top-25">
                    <h2>Gamla inbjudan</h2>                           
                    <div v-if="invitations.length > 0" class="margin-top-15">
                        <div v-for="invitation in invitations.filter(x => x.expired)" :key="invitation.id" class="invitation-item" @click="edit(invitation)" :style="{ 'background-color': invitation.recipients.find(x => x.user_id == user.id).participate_background_color}">
                        <div class="">
                            <div class="date-circle date-circle-grey">         
                                <b>{{invitation.day}}</b>
                                <br>
                                <span>{{ shortMonths[invitation.month] }}</span>
                            </div>
                        </div>
                        <div class="">
                            <b>{{ invitation.title  }}</b>

                            <div v-if="invitation.description.length > 79" v-html-to-text="invitation.description.substring(0,80) + '...'"></div>    
                            <div v-else v-html-to-text="invitation.description"></div>    
                        </div>
                        <div class="float-right">
                            <span v-if="!invitation.enable_answers">Inget svar behövs</span>
                            <span v-if="invitation.enable_answers">
                                <span v-if="invitation.recipients.find(x => x.user_id == user.id).participate == null">Meddela din närvaro</span>
                                <b v-else>{{ invitation.recipients.find(x => x.user_id == user.id).participate ? 'Du har tackat JA' : 'Du har tackat Nej' }}</b>
                            </span>  
                        </div>
                        
                        </div>
                    </div>
           
                <br>
            </section>      
        </main>
    </div>
</template>
<style scoped>
    .invitation-item{
        border-bottom:1px solid #ddd;
        overflow: auto;
        padding:10px 15px;
        background: white;
        display: block;
        text-decoration: none;
        position: relative;
    }

    .invitation-item:hover
    {
        cursor: pointer;
    }

    .date-circle
    {
        float:left;
        background-color:rgb(221, 168, 54);;
        border-radius: 30px;
        width: 60px;
        height: 43px;
        padding-top: 17px;
        text-align: center;
        line-height: 13px;
        color: white;
        margin: 0px 10px 0px 0px;
    }
    
    .date-circle-grey
    {
        background-color: #ccc;
    }

</style>