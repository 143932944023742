<script>
export default {
    props: ['model', 'prop', 'height','toolbar'],  
    data() {
        return {
                 
        }
    },
    created()
    {
        var self = this
        // setTimeout(() => {
            
        //     this.$refs.quill.$emit('set-html', '<h1>Test</h1>')
        // }, 5000);
    }
}
</script>
<template>  
<div :style="{ 'height': height == undefined ? '200px' : height }">
    <QuillEditor :contentType="'html'" :toolbar="toolbar == undefined ? 'essential' : toolbar" v-model:content="model[prop]"></QuillEditor>
</div>
</template>
<style scoped>
   div{
    margin-bottom: 55px;
   }
</style>