<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import UserInstruction from '@/components/UserInstruction.vue'

export default {
    components:{ List, Form,ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('accountSettings', 'account-settings/') 
        
        if(this.$route.params.id > 0)
        {
            var self = this;
            this.isAdding = false;
            this.$communicator.get('available-bookings/' + this.$route.params.id)
            .then(function(data)
            {             
                self.editItem = data;
            })
            .fail(function(error) {
                
            });   
        }
        else
        {
            this.isAdding = true;
            this.editItem = { 
                id: 0, 
                name: '', 
                information: '', 
                duration_time: '', 
                duration_unit: null, 
                day_available_start_time: null, 
                day_available_end_time: null, 
                include_in_public_site: false, 
                image_id: null
            }
        }

    },
    data() {
        return {             
            sorting: [
                { name: 'name', displayName: 'Namm' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            isAdding: false        
        }
    },   
    methods:
    {     
        goToAdminBookings(data)
        {  
            if(data != undefined)
            {
                this.$cache.updateCache('availableBookings', 'available-bookings/');
            }         
            this.$router.push({ name: 'adminBookings' }); 

        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },        
        form() {
            var form = [                   
                { sections: [{ sectionName: 'Namn och information', classes: "g-100", inputs: [ 
                            { type: 'text', prop: 'name', title: 'Namn', classes: "g-100", required: true },
                            { type: 'textEditor', prop: 'information', title: 'Information', classes: "g-50", required: true },
                            { type: 'textEditor', prop: 'rules', title: 'Regler/anvisningar', classes: "g-50", required: false },
                        ]
                    }, { sectionName: 'Timmar eller dagar', classes: "g-50", inputs: [
                        { type: 'select', prop: 'duration_unit', title: 'Enhet', classes: "g-100", required: true, updatesForm: true }, 
                        ]
                    }, { sectionName: 'Dagar', classes: "g-50", showIf: { prop: 'duration_unit', method: 'equals', value: 'Dagar'}, inputs: [
                        { type: 'select', prop: 'duration_time', title: 'Maximal längd på bokningen', classes: "g-100", required: true, selectItems: 'numbers' },                    
                        ]
                    }, { sectionName: 'Timmar', classes: "g-50", showIf: { prop: 'duration_unit', method: 'equals', value: 'Timmar'}, inputs: [
                        { type: 'select', prop: 'duration_time', title: 'Maximal längd på bokningen', classes: "g-100", required: true, selectItems: 'numbers' },                    
                        ]
                    },{ sectionName: 'Tillgänglig för bokning', classes: "g-100", inputs: [
                            { type: 'select', prop: 'day_available_start_time', title: 'Från klockan', classes: "g-50", required: true, selectItems: 'hours' }, 
                            { type: 'select', prop: 'day_available_end_time', title: 'Till klockan', classes: "g-50", required: true, selectItems: 'hours' }, 
                            ]
                    },
                    { sectionName: 'Antal bokningar', classes: "g-100", inputs: [
                        { type: 'select', prop: 'allow_multiple_bookings', title: 'Tillåt att användaren kan ha flera aktiva bokningar på ett bokningsobjekt samtidigt.', classes: "g-100", required: true,  selectItems: 'yesno' },                    
                        ]
                    },
                    { sectionName: 'Lägg till bilder', classes: "g-100", inputs: [
                        { type: 'image', prop: 'image_id', title: 'Klicka på den bild som visar bokningsobjektet', classes: "g-100" },                        
                        ]
                    }
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }];
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                form[0].sections.push({ sectionName: 'Visa även bokningsobjektet på den publika sidan', classes: "g-100", inputs: [                        
                    { type: 'select', prop: 'include_in_public_site', title: '', classes: "g-100", selectItems: 'yesno' }                        
                ]});
                form[0].sections.push({ sectionName: 'Extra information', classes: "g-100", showIf: { prop: 'include_in_public_site', method: 'equals', value: true }, inputs: [
                    { type: 'textEditor', prop: 'public_hidden_info', title: 'Det kan vara koder eller annan information som bara syns för de som bokat objektet', classes: "g-100" },                    
                ]});
            }

            return form;
        }       
    }
}
</script>
<template>
    <div v-fade-in v-if="accountSettings != null">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminBookings' }">Bokningar</router-link>
                &nbsp; >>  &nbsp;
                <b v-if="isAdding">Lägg till ett bokningsobjekt</b>
                <b v-else>Redigera ett bokningsobjekt</b>
            </div>
            <div class="float-right">              
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <h2 v-if="isAdding">Lägg till ett bokningsobjekt</h2>
            <h2 v-if="!isAdding">Redigera ett bokningsobjekt</h2>
            <div v-if="editItem != null">
                <Form :form="form" :item="editItem" :url="'available-bookings/'" :getItemFunction="goToAdminBookings" :inEditMode="true"></Form>
            </div>            
        </main>
    </div>
</template>
<style scoped>
    
</style>