<script>
export default {
    props: ['user'],
    data(){
        return {
           startGuide: false
        }
    },
    methods:
    {
        close()
        {
            this.user.hide_intro = true;
        }
    } 
}
</script>
<template>
        <div class="text-center" v-if="!startGuide">
            <h2> Hej {{ user.full_name }}!</h2>
            <b>Välkommen till jamii, vi hoppas att du ska trivas!</b><br><br>
            Eftersom du är ny här så har vi förberett en liten guide så att du ska känna dig hemma.<br>
            Den är i SIFFRA steg och tar inte allt för lång tid att ta sig igenom.<br><br>
            <div>
                <button class="btn btn-gray" @click="close">Stäng guiden, jag sitter hellre och klickar runt själv.</button>
                <button class="btn" @click="startGuide = true">Jag startar gärna guiden</button>
            </div>           
        </div>
        <div v-if="startGuide" v-fade-in>
            guide area
        </div>
</template>

<style scoped>
button{
    margin: 10px;
    padding: 15px 40px;
}


</style>