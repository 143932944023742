<script>
import lookups from '../helpers/lookups'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import Logo from '@/components/Logo.vue'
export default {
  components:{ Form, PopUp, Logo },
  data() {
    return {  
        username: '',
        password: '',
        errorType: null,
        forgotPwEmail: null,
        forgotPw: false,
        forgotPwSent: false,  
        noOfApiFetches: 0   
    }  
  },
  methods:
  {
    validateLogIn()
    {
      this.errorType = null;
      if(this.email == '')
      {
          this.errorType = 'emailBlank'
      } else if (this.password == '')
      {
          this.errorType = 'passwordBlank'
      }
      else
      {
        this.logIn();
      }
    },
    getCustomer()
    {
        var self = this;
        this.$communicator.get("customers/")
          .then(function(data)
          {
              self.$store.commit('setEntity', { entityName: 'customer', entity: data })    
              self.noOfApiFetches++;          
          })
          .fail(function(error) {
          
          }); 
    },
    getWorkGroups()
    {
        var self = this;
        this.$communicator.get("work-groups-for-user/")
          .then(function(data)
          {
             self.$store.commit('setEntity', { entityName: 'user_work_groups', entity: data })    
             self.noOfApiFetches++;    
          })
          .fail(function(error) {
          
          }); 
    },
    sendPwEmail()
    {
      if(this.forgotPwEmail == null) 
      {
        return;
      }
      var self = this;
        this.$communicator.get("send-new-password/", { email: this.forgotPwEmail })
          .then(function(data)
          {
              self.forgotPwSent = true;      
          })
          .fail(function(error) {
          
          });
    },
    closeSentEmailPopUp()
    {
      this.forgotPwSent = false;
      this.forgotPwEmail = null;
      this.forgotPw = false;
    }
  },
  created(){   
    var self = this;

    if(localStorage["samfallifyBgImg"] != undefined)
    {
      $("body").css("background-image", localStorage["samfallifyBgImg"]);
    }

    //ta bort time out sen.
    setTimeout(() => {

      $("#idorm").submit(function(e) {
        
      e.preventDefault();

      self.errorType = null;
      if(self.username == '')
      {
          self.errorType = 'usernameBlank'
      } else if (self.password == '')
      {
          self.errorType = 'passwordBlank'
      }
      else
      {
      
        var form = $(this);
        self.$communicator.login(form.serialize())
        .then(function(data) {
          localStorage.setItem("samfallifyToken", data.access_token);
          localStorage.setItem("samfallifyUser", JSON.stringify(data.user));
         

          lookups.getLookups();
          
          
          self.getCustomer();
          self.getWorkGroups();
          self.$store.commit('setEntity', { entityName: 'user', entity: { name: data.user.first_name + " " + data.user.last_name, id: data.user.id, user_level: data.user.user_level }});
          
          
        }).fail(function(error) {
          if(error.responseJSON != undefined && error.responseJSON.detail == 'Incorrect username or password')
            {
              self.errorType = 'userMissing';
            }
          }); 

        }
        });
      }, 100);

  },
  watch:
  {
     noOfApiFetches(newvalue) {
        if(newvalue == 2)
        {
          if(this.$route.query.returnpath != undefined)
            {
              this.$router.push(this.$route.query.returnpath);
            } else 
            {
              this.$router.push('/'); 
            }  
        }
     } 
  } 
}
</script>
<template>
  <section>
    <div class="login-content">
      Välkommen till<br>
      <Logo :width="150" :color="'rgb(225 182 117)'" :marginAuto="true"></Logo>      
      <h1 style="color: rgb(225 182 117)">Jamii</h1>
      <div class="login-btns">
        <form id="idorm">
          <input type="text" id="username" name="username" v-model="username" placeholder="E-mail"><br>
          <input type="password" id="password" name="password" v-model="password"  placeholder="Lösenord"><br>
          <input class="btn" type="submit" value="Logga in"><br>
        </form>
      </div>
      <div v-if="errorType != null" class="error login-error">
        <span v-if="errorType == 'usernameBlank'">Du måste mata in en e-mail</span>
        <span v-if="errorType == 'passwordBlank'">Du måste mata in ett lösenord</span>
        <span v-if="errorType == 'userMissing'">Vi kan inte hitta någon användare. Kontrollera e-mail och lösenord.</span>
      </div>
      <br><br>
      <a @click="forgotPw = true">Glömt lösenordet?</a>
      <div v-show="forgotPw" class="login-btns" style="margin-top: 15px">
        <input type="text" v-model="forgotPwEmail" placeholder="skriv in din email"/>
        <button class="btn btn-gray" @click="sendPwEmail()" style="width: 100%">Skicka ett nytt lösenord</button>
      </div>
  </div>
  </section>
  <div v-if="forgotPwSent">
    <PopUp :width="'500px'">
      <div class="Login-popup">
        <i class="fa-regular fa-paper-plane" style="margin: 30px 0px"></i>
        Ett mail har skickats till {{ forgotPwEmail }}. <br>
        Håll utkik i din mailkorg och logga sen in hos oss. :) <br>
        <button class="btn margin-top-15" @click="closeSentEmailPopUp">Tillbaka till inloggningen</button>
      </div>
    </PopUp>
  </div>
  
</template>

<style scoped>
section{
  text-align: center;
  width:100%;
  height: 100vh;
  display: flex;
}

i{
  display: block;
  font-size:60px;
  margin-top:15px;
}

h1{
  margin-top:5px;
  font-size: 46px;
  letter-spacing: 3px;
}

.login-content
{
  width:300px;
  margin:auto;
  background-color: rgba(255,255,255,0.9);
  padding:40px 20px 60px 20px;
  text-align: center;
  border-radius: 4px;
}
.login-error
{
  width:200px;
  margin:auto;
  margin-top:30px;
  padding:20px;
        border: 1px solid rgb(230, 70, 70);
        background-color: rgb(250, 202, 202) !important;
        color: rgb(230, 70, 70);
    }

.login-btns
{
  width:70%;
  margin:auto;
}

.login-btns input[type=submit]
{
  width:100%;
}
.login-btns button, input
{
  margin-bottom:5px;
}

.Login-new-customer-btn
{
  padding: 7px 15px;
    margin: 0px;
}

.Login-popup
{
  line-height: 30px;
  padding-bottom:30px;
}

@media only screen and (max-width: 1000px) {
  .login-content
  {
    
  }

  section{
  }
}
</style>
