<script>
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
export default {
    components:{ Form, PopUp, ToAdmin },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        this.$cache.createCache('accountSettings', 'account-settings/') 
    },
    data() {
        return {              
            form: [                   
                    { sections: [
                        { sectionName: 'Lägg till en ordningsregel', classes: "g-100", inputs: [
                            { type: 'textArea', prop: 'rule_of_procedure', title: 'ordningsregel', classes: "g-100", required: true }, 
                         
                            ]
                        }]
                    }],
        }
    },   
    methods:
    {
        edit()
        {
        },       
        getAccountSettings()
        {
           
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },
              
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <ToAdmin :section="'Konto'"></ToAdmin>
            
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>