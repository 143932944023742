<script>

import PopUp from '@/components/PopUp.vue';
export default {
    components: { PopUp},
    props: ['yes', 'no', 'text'],
    data(){
        return {
           areaWidth: this.width == undefined ? '80%' : this.width
        }
    }
}
</script>
<template>
    <PopUp :width="'400px'">
        <div class="YesNoPopUp-pop-up-area">
            <i class="fa-solid fa-triangle-exclamation"></i>
            {{text}}<br>
            <button class="btn" @click="yes">Ja</button>
            <button class="btn-gray btn" @click="no">Nej</button>
        </div>
    </PopUp>
</template>

<style scoped>
.YesNoPopUp-pop-up-area
    {
        text-align: center;
    }
    .YesNoPopUp-pop-up-area i
    {
        display: block;
        font-size: 40px;
        padding: 10px;
        color: rgb(247, 122, 122);
    }
    
    .YesNoPopUp-pop-up-area button
    {     margin: 10px 5px;

    }
</style>