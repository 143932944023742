<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import config from "../helpers/config";

const FilePond = vueFilePond();

export default {
  components: {
    FilePond,
  },
  props: ['id', 'type', 'categoryId'],
  data() {
    return {
      form: {
        title: "",
      },
      files: [],
    };
  },
  methods: {
    handlePondInit() {
      var self = this;
       this.$refs.pond._pond.setOptions({
        server: {
          process: function(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
          
            $.ajax({
              url: self.url,
              type: 'post',
              data: formData,
              processData: false,  // Important!
              contentType: false,  // Important!
              xhr: function() {
                var xhr = new window.XMLHttpRequest();               
                xhr.upload.addEventListener("progress", function(evt) {
                  if (evt.lengthComputable) {
                    progress(evt.lengthComputable, evt.loaded, evt.total);
                  }
                }, false);
                return xhr;
              },
              beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.samfallifyToken);
              },
              success: function(response) {
                load(response.filepath);
              },
              error: function(xhr, status, err) {
                if (status === 'abort') {
                  console.log('Request canceled', err);
                  abort();
                } else {
                  error('oh no!');
                }
              },
            });
          },
          revert: null,
          restore: null,
          load: null,
          fetch: null,
        },
      });
      
    }
  },
  computed:
  {
    url()
    {
      switch(this.type)
      {
        case 'documents':
          return config.urlToApi + 'documents/' + this.categoryId;
        case 'board':
          return config.urlToApi + 'board-documents/';
        case 'invitation':
          return config.urlToApi + 'invitation-documents/' + this.id;
        case 'workGroup':
          return config.urlToApi + 'work-group-documents/' + this.id;
        default:
          alert('lägg till en url')
          break;
      }
    }
  }
};
</script>
<template>
  <div class="float-right">Filformat som stödjs är pdf, word, excel och vanliga textfiler</div>
  <h2>Ladda upp dokument</h2>
  <file-pond
    ref="pond"
    name="file"
    allow-multiple="true" 
    max-files="10"
    label-idle="Dra och släpp dina filer här eller <span class='filepond--label-action'> Bläddra </span>"
    :files="files"
    :allowRevert="false"
    accepted-file-types="application/pdf, application/msword, application/vnd.ms-excel, text/plain, image/png, image/jpeg, image/gif"         
    @init="handlePondInit"
  />
</template>
<style scoped>
.filepond--credits
{
  display: none !important;
}
</style>
  