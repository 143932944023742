<script>
import config from "@/helpers/config"
import InvitationDocuments from '@/components/InvitationDocuments.vue'
import notifications from '@/helpers/notifications'
export default {
    components: { InvitationDocuments },
    created() {   
        this.getInvitation();
          
    },
    data() {
        return { 
            invitation: null,
            changeAnswer: false,
            files: []
        }
    },
    methods:
    {
        getInvitation()
        {
            var self = this;
            this.$communicator.get('invitations/' +  this.$route.params.id)
                .then(function(data)
                {
                   self.invitation = data;
                   if(self.notifications.length > 0)
                   {
                        notifications.getNotifications();
                   }

                })
                .fail(function(error) {
                    self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)   
                });
        },
        answer(participate)
        {
            var self = this;
            var params = { invitation_id: this.invitation.id, participate: participate }
            this.$communicator.post('invitation-answer/', params)
                .then(function(data)
                {
                   self.changeAnswer = false;
                   self.getInvitation();
                   self.$cache.updateCache('invitations', 'invitations/');
                })
                .fail(function(error) {
                    
                });
        }
    },
    computed:
    {      
        user() {return this.$store.state.user; },    
        recipient() { return this.invitation != null && this.user != null ? this.invitation.recipients.find(x => x.user_id == this.user.id)  : null },
        customer() { return this.$store.state.customer; },
        url() { return config.urlToServer },
        notifications() {  return this.$store.state.notifications != null ? this.$store.state.notifications : []; },
    },
}
</script>
<template>
    <div v-fade-in v-if="invitation != null && recipient != null">      
        <main class="opacity-white-bg">
            <div class="breadcrumbs margin-bottom-15">
                <router-link :to="{ name: 'invitations'}">
                    inbjudan
                </router-link> 
                &nbsp; >>  &nbsp;
                <b>{{invitation.title }}</b>
            </div>
            <h2 class="margin-top-15">{{ invitation.title }}</h2>             
            <b>Datum: </b>{{invitation.formatted_date }}<br>
            <b>Tid: </b>{{invitation.start_time }} <br><br>
            <div v-html="invitation.description"></div>
            <br>
            <InvitationDocuments :documents="invitation.files" :documentTitle="'Bifogade Dokument'" :invitationId="invitation.id"></InvitationDocuments>
            
            <br>
            <b>Den här inbjudan går ut till
                <span v-if="invitation.recipient_group_id==1 || invitation.recipient_group_id==2">
                    {{ invitation.recipient_group.name.toLowerCase() }}
                </span>
                <span v-if="invitation.recipient_group_id==3">
                    {{ invitation.work_groups.join(', ') }}
                </span>              
            </b>          
            <div v-for="recipient in invitation.recipients">
                <div v-if="recipient.user.id == user.id">
                    Dig
                </div>
                <div v-else>
                    {{ recipient.user.full_name }}
                    <span v-if="invitation.show_answers">
                        <span v-if="recipient.participate == null"> (har ej svarat)</span>
                        <span v-else-if="recipient.participate"> (har tackat JA)</span>
                        <span v-else> (har tackat nej)</span>
                    </span>
                </div>
            </div>
          
            <div class="Invitations-answer-box info" v-if="invitation.enable_answers" :style=" { 'background-color': recipient.participate_background_color}">
                <div v-if="recipient.participate == null">
                    <b>Meddela om du tänker delta eller inte.</b>
                    <br><br>
                    <button class="btn" @click="answer(true)">Ja, jag kommer.</button>
                    <button class="btn btn-gray" @click="answer(false)">Nej</button>
                </div> 
                <div v-else>
                    <b v-if="recipient.participate">Du har svarat JA på inbjudan.</b>
                    <b v-else>Du har svarat NEJ på inbjudan</b><br>
                    <div class="margin-top-15">
                        <a v-if="!changeAnswer" @click="changeAnswer = true">Klicka här om du vill ändra dig</a>
                        <span v-if="changeAnswer">
                            <button class="btn" @click="answer(true)">Ja, jag kommer.</button>
                            <button class="btn btn-gray" @click="answer(false)">Nej</button>
                        </span>
                    </div>
                </div>       
            </div>      
        </main>
    </div>
</template>
<style scoped>  
    .Invitations-answer-box
    {
        width: 500px;
        margin: auto;
        border: 1px solid #ccc;
        padding: 20px;
        margin-top:50px;
        margin-bottom:50px;
        text-align: center;
    }

    h2{
        margin-top: 30px;
        font-size: 20px;
    }

    @media only screen and (max-width: 1000px) {
        .Invitations-answer-box
        {
            width: calc(100% - 42px);
        }
    }

</style>