<script>
export default {
    props: ['section']
}
</script>
<template>
   <div class="breadcrumbs">
      <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
      &nbsp; >>  &nbsp;
      <b>{{section}}</b>
   </div>
</template>
