<script>
export default {
    props: ['sectionToEdit', 'buttonText'],
    data(){
        return {
           
        }
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        }
    },
    methods:
    {
        goToAdminSection()
        {
            this.$router.push({ name: this.sectionToEdit })
        }
    },
}
</script>
<template>  
   <button class="btn btn-admin float-right" v-if="!this.$isMobile && (user.user_level == 'Redaktör' || user.user_level == 'SamfallifyCrew')"  @click="goToAdminSection">
    <i class="fa-solid fa-user-shield" style="color: black"></i> 
    {{ buttonText }}
   </button>
</template>
<style scoped>
   
</style>