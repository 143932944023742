<script>
import Incoming from '../components/Incoming.vue'
export default {
    components: {Incoming },
    data(){
        return {
            month: '',
            currentDate: null,
            noOfDays: null,
            today: new Date(),
            months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
            days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
            daysInNumbers: [6,0,1,2,3,4,5],
            allDaysInMonth: []            
        }
    },
    created()
    {     
        this.currentDate = new Date();
        this.renderCalenderMonth(this.currentDate);
        this.$cache.createCache('bookings','bookings/') ;  
    },
    computed: {
        bookings() {   return this.$store.state.bookings; }
    },
    methods:
    {
        showPrevMonth()
        {
            this.currentDate.setMonth(this.currentDate.getMonth()-1);
            this.renderCalenderMonth(this.currentDate);
        },
        showNextMonth()
        {
            this.currentDate.setMonth(this.currentDate.getMonth()+1);
            this.renderCalenderMonth(this.currentDate);
        },
        renderCalenderMonth(date)
        {
            this.month = this.months[date.getMonth()];
            this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
            var dayInMonth = 1;
            var dayInNextMonth = 1;
            
            //fill calender with days
            this.allDaysInMonth = [];
            for(var i = 0; i <= 41; i++)
            {
                if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                {
                   //calculate day date and if its sunday and if its today
                    var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);
                    
                    var isToday = this.today.getFullYear() == dayDate.getFullYear() && this.today.getMonth() == dayDate.getMonth() && this.today.getDate() == dayDate.getDate();
                    this.allDaysInMonth.push({ date: dayDate, dateString: this.getDateString(dayDate), day: dayInMonth++, isCurrentMonth: true, isSunday: dayDate.getDay() == 0, isToday: isToday });
                }
                else if(i < this.daysInNumbers[firstDay.getDay()])
                {
                    this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false, isToday: false });
                    
                } else{
                    this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false, isToday: false });                    
                }
            }

            //fil previous months visible days
            var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            for(var i = 41; i >= 0; i--)
            {
                if(this.allDaysInMonth[i].day  == 'prevMonth')
                {
                    this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                }
            }      
        },
        getDateString(date)
        {
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        }
    }
}
</script>
<template>
    <main class="template-two-cols-right-20-percent" v-fade-in>
        <section>
            <h2>Kalender</h2>
            <div class="row">
                <div class="width33 month-link" @click="showPrevMonth">&lt; visa {{ currentDate.getMonth() == 0 ? 'december' : months[currentDate.getMonth()-1] }}  </div>
                <div class="width33 month">{{ this.month }} {{ this.currentDate != null ? this.currentDate.getFullYear() : '' }}</div>
                <div class="width33 text-right month-link" @click="showNextMonth">visa {{ months[currentDate.getMonth()+1] }} &gt;</div>
            </div>
            <div class="row days">
                <div v-for="day in days" class="width14">
                {{ day }}               
                </div>
            </div>   
            <div v-for="(day,index) in allDaysInMonth" class="width14 float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '' }" :class="{'wow-color': day.isToday }">
                <div class="day-content">
                    <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }">{{ day.isToday ? day.day + ' (idag)' : day.day }}</i>  
                    <div v-if="bookings != null">                 
                        <p v-for="booking in bookings.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString)">
                            <span class="float-right">
                                <i class="fa-regular fa-clock"></i> <span v-date="{date: booking.start_date, type: 'time'}"></span>-<span v-date="{date: booking.end_date, type: 'time'}"></span>     
                            </span>
                            <i class="fa-regular fa-calendar-check"></i> {{ booking.booking_item.name }}<br>
                        </p>
                    </div>
                </div>             
            </div>       
        </section>
        <aside>
            <Incoming></Incoming>
        </aside>
    </main>
</template>
<style scoped>
    section{
        width: 100%;
        overflow: auto;
    }

    .row{
        width:calc(100%-10px);
        overflow: auto;
    }

    .day-content
    {
        margin: 0px 5px 0px 5px;
        height: 120px;
    }

    .today
    {
        background-color: red;
    }

    .day-content p
    {
        font-size: 13px;
        border: 1px solid blue;
        padding:5px;
        margin:1px;
    }
    
    .row div{
        padding:10px 0px;

    }
    
    .dates {
        padding:4px 0px;
        border-right: 1px dashed #ddd;
   
        border-bottom: 1px dashed #ddd;

    }
    
    .month{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .month-link
    {
        cursor: pointer;
    }
    
    .days{
        text-align: center;
        font-weight: bold;
    } 
    
    .days div{
        border-right: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        
    }

    .days div:first-child{
        border-left: 1px dashed #ddd;
    }
    .row div{
        float: left;
    }

    .width14
    {
        width: 14%;
    }

   
</style>