<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('forums', 'forums/');
    },
    data() {
        return {   
            columns: [                             
                { name: 'edit', displayName: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: 'Ta Bort', isDelete: true, align:'center', width: 60, hideFilter: true, hideIfEdit: true},
                { name: 'formatted_category', displayName: 'Kategori', width: 500, hideFilter: true},
                { name: 'description', displayName: 'Beskrivning', width: 600, hideFilter: true},
                { name: 'formatted_created_date', displayName: 'Skapad', width: 140, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'category', displayName: 'Kategori' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            form: [                   
                    { sections: [
                        { sectionName: 'Lägg till en kategori', classes: "g-100", inputs: [
                            { type: 'text', prop: 'category', title: 'Namn på kategorin', classes: "g-100", required: true }, 
                            { type: 'text', prop: 'description', title: 'Beskrivning', classes: "g-100" }, 
                         
                            ]
                        }]
                    }],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, category: ''};
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('forums/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getForums();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getForums()
        {
            this.editItem = null;
            this.$cache.updateCache('forums', 'forums/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        forums() {  
            return this.$store.state.forums != null ? this.$store.state.forums : []
        }        
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en kategori</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Forumet'"></ToAdmin>

            <h2>Forumet</h2>
           
                <div v-if="editItem != null">
                    <PopUp>
                        <Form :form="form" :item="editItem" :url="'forums/'" :getItemFunction="getForums" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List :items="forums" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'kategorier'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>