<script>

import InvitationDocuments from '@/components/InvitationDocuments.vue'
export default {
    components:{ InvitationDocuments },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        var self = this;
        this.$communicator.get('admin-invitations/' +  this.$route.params.id)
            .then(function(data)
            {
                self.invitation = data;
            })
            .fail(function(error) {
                
            });
    
        this.$cache.createCache('boardMembers', 'board-members/');
        this.$cache.createCache('workGroups', 'work-groups/');

        setTimeout(() => {
            $("#AdminInvitationsAdd-content-area").css({ 'min-height': window.innerHeight-150 + "px"});
        }, 1000);


    },
    data() {
        return {            
            invitation: null, 
            isEditing: false,
            tab: 1,
            tabsComplete: 0,            
        }
    },   
    methods:
    {
        getInvitation()
        {
            var self = this;
            this.$communicator.get('admin-invitations/' +  this.$route.params.id)
                .then(function(data)
                {
                   self.invitation = data;
                   self.isEditing = false;

                })
                .fail(function(error) {
                    
                });
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },   
        users() { return this.$store.state.users != null ? this.$store.state.users : null },   
        recipientGroups() { return this.$store.state.recipientGroups != null ? this.$store.state.recipientGroups: [] },
        boardMembers() { return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : [] },
        workGroups() { return this.$store.state.workGroups != null ? this.$store.state.workGroups : null }  
    }
}
</script>
<template>
    <div v-fade-in v-if="users != null && workGroups != null && invitation != null">
        <main id="AdminInvitationsAdd-content-area" class="opacity-admin-bg overflow-auto position-relative" v-if="users != null">          
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminInvitations' }">Inbjudan</router-link>
                &nbsp; >>  &nbsp;
                <b>{{invitation.title}}</b>
            </div>
            <ul>
                <li @click="tab = 1" :class="[tab == 1 ? 'wow-color' : '']"><h2>1. Sammanfattning</h2></li>
                <li v-if="invitation.enable_answers" @click="tab = 2" :class="[tab == 2 ? 'wow-color' : '']"><h2>2. Svar från mottagare</h2></li>
                <li v-if="!invitation.enable_answers" @click="tab = 3" :class="[tab == 3 ? 'wow-color' : '']"><h2>2. Mottagare</h2></li>
            </ul>                
            <div v-show="tab == 1">
                Följande inbjudan har skickas ut:
                <div style="background-color: white; padding: 20px; width: 80%; margin: auto; border-radius: 5px; border: 1px solid #ccc; margin-top: 20px; margin-bottom:20px;">                
                   
                    <h3 class="text-center">{{ invitation.title }} </h3>
                    <b>Datum: </b>{{invitation.formatted_date }}<br>
                    <b>Tid: </b>{{invitation.start_time }} <br><br>
                    <InvitationDocuments :documents="invitation.files" :documentTitle="'Bifogade Dokument'" :invitationId="invitation.id"></InvitationDocuments>
                    <div v-html="invitation.description"></div>
                </div>            
            </div>  
            <div v-show="tab == 2">
                <table>
                    <tr>
                        <td>
                            <h4>Deltagare som svarat JA</h4>
                            <div v-show="!invitation.recipients.some(x => x.participate)">Ingen vill komma :(</div>
                            <div v-for="recipient in invitation.recipients.filter(x => x.participate)" class="AdminInvitationsAdd-users-list"> 
                                    {{ recipient.user.full_name }}
                            </div>
                        </td>
                        <td>
                            <h4>Deltagare som svarat NEJ</h4>
                            <div v-show="!invitation.recipients.some(x => !x.participate && x.participate != null)">Ingen har svarat nej :)</div>
                            <div v-for="recipient in invitation.recipients.filter(x => !x.participate && x.participate != null)" class="AdminInvitationsAdd-users-list"> 
                                    {{ recipient.user.full_name }}
                            </div>
                        </td>
                        <td>
                            <h4>Väntar svar från följande deltagare</h4>
                            <div v-for="recipient in invitation.recipients.filter(x => x.participate == null)" class="AdminInvitationsAdd-users-list"> 
                                {{ recipient.user.full_name }}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>           
            <div v-show="tab == 3">
                <table>
                    <tr>
                        <td>
                            <h4>Följande mottagare har fått inbjudan</h4>
                            <div v-for="recipient in invitation.recipients" class="AdminInvitationsAdd-users-list"> 
                                {{ recipient.user.full_name }}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>           
        </main>
    </div>   
</template>
<style scoped>
    td{
        vertical-align: top;
    }

    table{
        width: 80%;
        margin: auto;
    }

    #AdminInvitationsAdd-content-area
    {
        margin-bottom: 0px;
    }

    .AdminInvitationsAdd-bottom-btn
    {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

   ul{
    display: block;
    list-style: none;
    overflow: auto;
   }

   li{
    padding: 20px 0px;
    width: 25%;
    float:left;
    text-align: center;   
    cursor: pointer; 
   }

   li:hover{
    background-color: rgb(252, 239, 239);
   }

   .AdminInvitationsAdd-btn
   {
    padding:10px 20px;
    margin: 10px 5px;
   }

   .AdminInvitationsAdd-users-list
   {
    line-height: 25px;
   }

   .AdminInvitationsAdd-popup
    {
    line-height: 30px;
    padding-bottom:30px;
    }

    .fa-paper-plane
    {
        font-size: 40pt;
    }
   
</style>