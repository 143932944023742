export default {
    adaptHtml(html, backgroundColor){
      if(html == null)
      {
        return '';
      }
      html = html.replace(/<h1>/g, "<h1 style='font-size: 20px; color: "+ backgroundColor+"'>");
      html = html.replace(/<h2>/g, "<h2 style='color: "+ backgroundColor+"'>");
      return html;
    }
}