<script>
import Messages from './components/Messages.vue'
import Menu from './components/Menu.vue'
import UserMenu from './components/UserMenu.vue'
import lookups from './helpers/lookups'
import config from "@/helpers/config"
import notifications from './helpers/notifications'
export default {
  name: 'App',
  components: { Menu, UserMenu, Messages },
  data() {
    return {        
        customer: null,
        positionWidth: null,
        positionLeft: null
    }  
  },
  created()
  {
      if(localStorage.samfallifyUser == undefined || localStorage.samfallifyToken == undefined){
       
        this.$router.push('/login')      
      } else
      {      
        var user = JSON.parse(localStorage.samfallifyUser);
        this.$store.commit('setEntity', { entityName: 'user', entity: { name: user.first_name + " " + user.last_name, id: user.id, user_level: user.user_level }}); 
        lookups.getLookups();   
        this.getCustomer(); 
        this.getWorkGroups(); 
        notifications.getNotifications();

        var self = this;    
          window.addEventListener('resize', function(event) {
              self.setUserMenuSize();
          }, true);
      }
    },
    mounted()
    {
        this.setUserMenuSize();
        $(document).on('click', function() {
            $('.v-tooltip').remove();
        });
    },
    methods:
    {
        setUserMenuSize()
        {
            var position = document.getElementById("main-content").getBoundingClientRect();
            this.positionLeft = position.x;
            this.positionWidth = position.width;
        },
        getCustomer()
        {
          var self = this;
          this.$communicator.get("customers/")
            .then(function(data)
            {
                self.$store.commit('setEntity', { entityName: 'customer', entity: data })   
                if(data.background_image_name != null)
                {
                  var url = 'url("' + self.url + 'uploads/'+ data.id+'/images/'+ data.background_image_name + '")'
                  $("body").css("background-image", url);
                  localStorage.setItem("samfallifyBgImg", url);
                } 
                
                
                
            })
            .fail(function(error) {
            
            }); 
        },
        getWorkGroups()
        {
            var self = this;
            this.$communicator.get("work-groups-for-user/")
              .then(function(data)
              {
                self.$store.commit('setEntity', { entityName: 'userWorkGroups', entity: data })                            
              })
              .fail(function(error) {
              
              }); 
        }
    },
    computed:
    {      
        url() { return config.urlToServer },              
    }    
}
</script>
<template> 
  <Menu v-if="!this.$isMobile && this.$store.state.customer != null && this.$store.state.user != null" :positionLeft="positionLeft"></Menu>
  <UserMenu v-if="this.$store.state.customer != null  && this.$store.state.user != null" :positionLeft="positionLeft" :positionWidth="positionWidth"></UserMenu>
  <router-view></router-view>
  <Messages></Messages>
</template>
<style>
 
</style>
  