import store from '../store'
export default {
    removeMessage(){
      store.commit('setEntity', { entityName: 'message', entity: null }); 
    },

    setSuccessMessage(successMessage)
    {
      store.commit('setEntity', { entityName: 'message', entity: { type: 'success', successMessage: successMessage }}); 
      setTimeout(() => {
          this.removeMessage()
      }, 5000);
    },
    
    setValidationErrorMessage(errors)
    {
      store.commit('setEntity', { entityName: 'message', entity: { type: 'errors', errors: errors }}); 
    },
    setInfoMessage(message)
    {
      store.commit('setEntity', { entityName: 'message', entity: { type: 'info', message: message }}); 
    },


}