<script>
export default {
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');           
        }   
    },
    data() {
        return { 
            areas: [
                { url: 'admin/news/', title: 'Aktuellt från styrelsen', icon: 'fa-solid fa-paper-plane', text: 'Här kan du redigera de aktuella meddelanden som användaren möts av varje gång hen loggar in.' },
                { url: 'admin/board-members/', title: 'Styrelsen', icon: 'fa-solid fa-people-line', text: 'Här hittar du allt du behöver för att kunna hålla styrelsen aktuell. ' },
                { url: 'admin/members/', title: 'Medlemmar', icon: 'fa-solid fa-people-roof', text: 'Här har du som redaktör möjlighet att lägga till nya medlemmar och ta bort medlemmar som till exempel flyttar.' },
                { url: 'admin/work-groups/', title: 'Arbetsgrupper', icon: 'fa-solid fa-people-roof', text: 'Här kan du lägga till nya arbetsgrupper.' },
                { url: 'admin/forums/', title: 'Forumet', icon: 'fa-solid fa-comments', text: 'Här kan du lägga till nya avdelningar i forumet.' },
                { url: 'admin/bookings/', title: 'Bokningar', icon: 'fa-solid fa-calendar-days', text: 'Hantera vad som går att hyra/låna inom din förening.' },
                { url: 'admin/documents/', title: 'Ladda upp dokument', icon: 'fa-solid fa-file', text: 'Här kan du hantera föreningens gemensamma dokumentation.' },
                { url: 'admin/invitations/', title: 'Inbjudan', icon: 'fa-solid fa-handshake-simple', text: 'Skapa inbjudan till årsmöten, städdagar, trivselaktiviter etc' },
                { url: 'admin/statutes/', title: 'Stadgar', icon: 'fa-solid fa-gavel', text: 'Hä kan du lägga upp föreningens stadgar.' },
                { url: 'admin/rules-of-procedure/', title: 'Ordningsregler', icon: 'fa-solid fa-person-harassing', text: 'Här kan du lägga upp föreningens ordningsregler' },
                { url: 'admin/image-vault/', title: 'Bildbanken', icon: 'fa-solid fa-image', text: 'Här lägga du upp de bilder du vill använda i jamii.' },
                { url: 'admin/public-site/', title: 'Publika sidan', icon: 'fa-solid fa-globe', text: 'Här kan du bestämma om föreningen ska ha en publik sida och ändra utseendet och innehållet på denna.' },
            ]
        }
    },   
    computed:
    {
        user()
        {
            return this.$store.state.user;
        }
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="btn-admin Admin-go-to-user-tip">Tips! Du kan när som helst gå över till användarläget genom att klicka på <i class="fa-solid fa-user" style="color: black"></i> ovan.</div>              
            <h2>Hej Redaktör!</h2>
            <div>Här kan du anpassa appen efter din förenings behov. Välj någon av länkarna nedan för att börja anpassa...</div>  
            <div class=" margin-top-15">

                    <router-link :to="area.url" v-for="area in areas" class="g-25">
                        <div class="Admin-area">
                            <div>
                                <i :class=area.icon></i>
                            </div>
                            <div>
                                <h3>{{ area.title }}</h3>
                                <p> {{ area.text }}</p>
                                
                            </div>
                        </div>
                    </router-link>
            </div>         
        </main>
    </div>
</template>
<style scoped>
.Admin-go-to-user-tip {
    position: absolute;
    right: 20px;
    padding:10px 20px;
    width: 260px;
}

.Admin-area
{
  padding:28px 20px;
  overflow: auto;
  float: left;
  width: calc(100% - 40px);
  min-height: 175px;
  border: 1px solid  rgba(250, 233, 197, 0.95);
}

.Admin-area:hover
{
  border: 1px solid #4c6675;
}

.Admin-area i
{
 font-size: 30pt;
}

.Admin-area p
{
 color: black;
}

@media only screen and (max-width: 1500px) {
    .Admin-area p
    {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    }

    .Admin-area h3 {
    margin: 14px 0px 8px 0px;
    }

    .Admin-area
    {
        min-height: 190px;
    }
}
</style>