<script>
    export default {
        props: ['prop', 'value', 'startWithYears', 'hasValidationErrors'],
        emits: ['update:value'],
        data() {
            return {
                dispDropdownList: false,
                month: '',
                currentDate: null,
                noOfDays: null,
                today: new Date(),
                months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
                years: [],
                days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
                daysInNumbers: [6,0,1,2,3,4,5],
                allDaysInMonth: [],
                showCalendar: this.startWithYears && this.value == null ? false : true
            }
        },
        methods: {
            selectDate(item) {             
                this.$emit('update:value', item.dateString);
                this.dispDropdownList = false; 
            },
            clear() {
                this.$emit('update:value', null);
            },
            toggleOptions() {
                this.dispDropdownList = !this.dispDropdownList;
                if (this.dispDropdownList) {
                    $("#app").append($("#date-dropdown-" + this.prop));
                    var elmPosition = document.getElementById("date-content" + this.prop).getBoundingClientRect();
                    $("#date-dropdown-" + this.prop).css({width: elmPosition.width-9 + 'px', top: elmPosition.top + 32 + $(window).scrollTop() + 'px', left: elmPosition.left + 'px'});
                }
            },       
            showPrevMonth()
            {
                this.currentDate.setMonth(this.currentDate.getMonth()-1);
                this.renderCalenderMonth(this.currentDate);
            },
            showNextMonth()
            {
                this.currentDate.setMonth(this.currentDate.getMonth()+1);
                this.renderCalenderMonth(this.currentDate);
            },
            renderCalenderMonth(date)
            {
                this.month = this.months[date.getMonth()];
                this.year = date.getFullYear();
                this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
                var dayInMonth = 1;
                var dayInNextMonth = 1;
                
                //fill calender with days
                this.allDaysInMonth = [];
                for(var i = 0; i <= 41; i++)
                {
                    if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                    {
                    //calculate day date and if its sunday and if its today
                        var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);
                        var isToday = this.getDateString(dayDate) == this.getDateString(this.today); 
                        this.allDaysInMonth.push({ date: dayDate, dateString: this.getDateString(dayDate), day: dayInMonth++, isCurrentMonth: true, isToday: isToday, isSunday: dayDate.getDay() == 0 });                     
                    }
                    else if(i < this.daysInNumbers[firstDay.getDay()])
                    {
                        this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false });
                        
                    } else{
                        this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false });                    
                    }
                }

                //fil previous months visible days
                var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
                for(var i = 41; i >= 0; i--)
                {
                    if(this.allDaysInMonth[i].day  == 'prevMonth')
                    {
                        this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                    }
                }      
            },
            getDateString(date)
            {
                return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
            },
            selectYear(year)
            {
                var date = new Date( year + '-01-01');
                this.currentDate = date;
                this.renderCalenderMonth(date);
                this.showCalendar = true;
            },
            showYears(yearSelected){
                this.years = [];
                for(var i = 1920; i <= new Date().getFullYear()+12; i++)
                {
                    this.years.push({year:i, isYearSelected: i == yearSelected });
                }
                this.showCalendar = false;
                var self = this;
                setTimeout(() => {
                    var el = document.getElementById('date-dropdown-' + self.prop);
                    var elYear = document.getElementById('date-year-' + self.prop + yearSelected);
                    el.scrollTop = elYear.offsetTop;
                }, 40);
            } 
        },
        created()
        {
            var self = this;
            //populate years
            for(var i = 1920; i <= new Date().getFullYear()+12; i++)
            {
                this.years.push({year:i, isYearSelected: false });
            }
 
            this.currentDate = this.value == null ? new Date() : new Date(this.value);
            this.renderCalenderMonth(this.currentDate);

            //close if clicking outside search section.
            $(document).mousedown(function (e) {
                var container = $("#date-dropdown-" + self.prop);
                var inputArea = $("#date-input" + self.prop);

                // if the target of the click isn't the container nor a descendant of the container (plus input)
                if (!container.is(e.target) && container.has(e.target).length === 0 && !inputArea.is(e.target) && inputArea.has(e.target).length === 0) {
                    self.dispDropdownList = false;
                    $("#date-content" + self.prop).append($("#date-dropdown-" + self.prop));
                }
            });
        }        
    }
</script>

<template>
    <div class="date" :id="'date-content'+ prop">                       
        <div @click="toggleOptions">
            <div :id="'date-input' + prop" class="date-input date-chosenItem-input" :class="hasValidationErrors ? 'validation-error-border':''">
                <span v-show="value != null" style="color: #555555">{{value}}</span>
                <span v-show="value == null" style="color:#999999">välj datum...</span>
                <div class="fa fa-caret-down" v-show="!dispDropdownList"></div>
                <div class="fa fa-caret-up" v-show="dispDropdownList"></div>
            </div>
        </div>

        <div class="date-dropdown" v-show="dispDropdownList" :id="'date-dropdown-' + prop">
            <div v-show="!showCalendar" :id="'date-year-' + prop">
                <div v-for="year in years" :id="'date-year-' + prop + year.year" class="years" :class="year.isYearSelected ? 'Date-year-selected' :''" v-hover @click="selectYear(year.year)">
                {{ year.year }}</div>
            </div>
            <div v-show="showCalendar">
                <div class="row">
                    <div class="month-link text-left" @click="showPrevMonth" v-tooltip="'Visa föregående månad'">&lt;</div>
                    <div class="month-year">{{ month }}</div>
                    <div class="month-year cursor-pointer" @click="showYears(year)" v-tooltip="'Ändra året'">{{ year }}</div>
                    <div class="text-right month-link" @click="showNextMonth" v-tooltip="'Visa nästa månad'"> &gt;</div>
                </div>
                <div class="row days">
                    <div v-for="day in days">
                    {{ day }}               
                    </div>
                </div>   
                <div v-for="(day,index) in allDaysInMonth" class="float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '', 'background-color': day.isToday ? '#ddd' : '' }">
                    <div class="day-content" @click="selectDate(day)" v-hover>
                        <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }">{{ day.day }}</i>  
                    
                    </div>             
                </div> 
            </div>   
        </div>                  
    </div>  
</template>
<style scoped>

    .years
    {
        width: calc(25% - 30px);
        display: inline-block;
        padding:15px;
        cursor: pointer;
    }
    .date-input
    {
        width: calc(100% - 29px);
    }

    .date {
        position: relative;
        margin: 0px;
        padding: 0px;
        line-height: normal !important;
    }

    .Date-year-selected
    {
        background-color: #ddd;
    }

    .date-chosenItem-input {
        caret-color: transparent;
        cursor: pointer;
    }

    .date .fa {
        position: absolute;
        color: #888;
        right: 14px;
        top: 10px;
        cursor: pointer;
    }

    .date input {
        width: calc(100% - 12px);
        
    }

    .date-input-section {
        padding: 4px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }

    .date-dropdown {
        position: absolute;
        top: 35px;
        left: 0px;
        z-index: 1002;
        background-color: white;
        min-width: 230px;
        height: 320px;
        overflow: auto;
        border: 1px solid #ccc;
    }

    div.error, div.error:focus {
        border: 1px solid #d9534f;
    }

    /* from calendar */

    .row{
        width:calc(100%-10px);
        overflow: auto;
    }
    .day-content
    {
        padding: 10px 5px 10px 5px;
        cursor: pointer;
    }

    .row div{
        padding:10px 0px;

    }
    
    .dates {
        width: calc(14% - 1px)
    }
    
    .month-year{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        width: 35%;
    }

    .month-link
    {
        font-weight: bold;
        cursor: pointer;
        margin: 0px 10px;
        width: calc(15% - 20px);
    }
    
    .days{
        text-align: center;
        font-weight: bold;
    } 
    
    .days div{       
        width: calc(14% - 1px)      
    }

    .row div{
        float: left;
    }

    .validation-error-border
    {
        border: 1px solid rgb(230, 70, 70);
        background-color: rgb(250, 202, 202) !important;
        color: rgb(230, 70, 70);
    }

</style>