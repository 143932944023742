<script>
import Tabs from '../components/Tabs.vue'
import CanvasDocuments from '@/components/canvas/CanvasDocuments.vue'
import CanvasCommunicate from '@/components/canvas/CanvasCommunicate.vue'
import CanvasMembers from '@/components/canvas/CanvasMembers.vue'
import CanvasForum from '@/components/canvas/CanvasForum.vue'
export default {
    components: { Tabs, CanvasDocuments, CanvasCommunicate, CanvasMembers, CanvasForum },
    data() {
        return {
            selectedTab: 'Kommunicera',
            updateChat: { timeToUpdate: false },
        };
    },
    created() {      
        this.$cache.createCache('boardMembers', 'board-members/');     
    },
    computed:
    {
        notifications() {  
            return this.$store.state.notifications != null ? this.$store.state.notifications : []; 
        },
        customer() {  return this.$store.state.customer == null ? {} : this.$store.state.customer; },
        boardMembers() {  return this.$store.state.boardMembers != null ? this.$store.state.boardMembers.map(x => x.user_name) : [] },
        tabs() {

            if(this.customer.disable_board_forum) {
                return ['Kommunicera', 'Deltagare', 'Filer'];          
            }
            return ['Kommunicera', 'Deltagare', 'Forumet', 'Filer'];
        }
    }
};
</script>
<template>
    <div v-fade-in>
        <main class="opacity-white-bg">
            <h2> Styrelsen</h2>
            <Tabs :tabs="tabs" v-model:selectedTab="selectedTab" :notifications="notifications.filter(x => x.level.menu == 'Styrelsen')">               
            </Tabs>     
            <div v-show="selectedTab == 'Kommunicera'">
                <CanvasCommunicate :updateChat="updateChat"></CanvasCommunicate>
            </div>
            <div v-show="selectedTab == 'Deltagare'">
                <CanvasMembers :members="boardMembers"></CanvasMembers>
            </div>
            <div v-show="selectedTab == 'Forumet'">
                <CanvasForum :isBoard="true"></CanvasForum>
            </div>
            <div v-show="selectedTab == 'Filer'">
              <CanvasDocuments :updateChat="updateChat"></CanvasDocuments>
            </div>            
        </main>
    </div>

</template>
<style scoped>
    .Community-tabs-wrapper
    {
        width:100%;
        border-bottom: 1px solid black;
        margin-bottom: 15px;

    }

    .Community-tabs-wrapper h2 {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        margin: 0px;
    }
</style>
