<script>
export default {
    props: ['level','levelName','notifications', 'color', 'right', 'left', 'top'],
    data(){
        return {         
           
        }     
    },
    computed: {
        numberOfNotifications() {

            if(this.notifications.length == 0)
            {
                return 0;
            }

            var noOfNotifications = 0;
            for(var i = 0; i < this.notifications.length; i++)
            {
                if(this.notifications[i].level[this.level] == this.levelName)
                {
                    noOfNotifications++;
                }
            }

            return noOfNotifications;
        }
    }
}
</script>
<template>
<div v-if="numberOfNotifications > 0" :style="{ 'right': right, 'left': left, 'top': top, 'background-color': this.color }">{{numberOfNotifications}}</div>
</template>

<style scoped>
   div
   {
    position: absolute;
    top:10px;
    height: 17px;
    width: 20px;
    background-color: aliceblue;
    border-radius: 10px;
    font-size: 9pt;
    padding-top: 3px;
    text-align: center;
    color: white;

   }
</style>